import { Button, Modal, ModalProps } from "antd";
import {
  IconConfirmModal,
  IconErrorModal,
  IconSuccessModal,
} from "assets/icons";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/cn";
import useModal from "utils/hooks/useModal";
import Icon from "@ant-design/icons";
import { ModalMessageType } from "constants/enums";

export interface IModalMessageProps extends ModalProps {
  children?: ReactNode;
  classNameChildren?: string;
  title?: string | ReactNode;
  showIcon?: boolean;
  onCancel?: () => any;
  onConfirm?: () => any;
  textCancel?: string;
  textConfirm?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  closeWhenCancel?: boolean;
  closeWhenConfirm?: boolean;
  variant?: ModalMessageType;
}

//* Usage
// const { openModal } = useModal;
// openModal({ title: "quang", children: "iu em" })

export function ModalMessage(props: IModalMessageProps) {
  const { t } = useTranslation();

  const {
    children,
    classNameChildren,
    variant = "success",
    showIcon = true,
    title,
    onCancel,
    onConfirm,
    textCancel = t("common.cancel"),
    textConfirm = t("common.ok"),
    showCancel = true,
    showConfirm = true,
    closeWhenCancel = true,
    closeWhenConfirm = true,
    ...rest
  } = props;
  const { closeModal } = useModal();

  const { isSuccess, isError } = useMemo(
    () => ({
      isSuccess: variant === ModalMessageType.SUCCESS,
      isError: variant === ModalMessageType.ERROR,
    }),
    [variant]
  );

  const handleCloseModal = () => {
    closeModal?.();
  };

  const handleCancel = () => {
    onCancel?.();
    closeWhenCancel && handleCloseModal();
  };

  const handleConfirm = () => {
    onConfirm?.();
    closeWhenConfirm && handleCloseModal();
  };

  const IconComponent = useMemo(() => {
    return isSuccess
      ? IconSuccessModal
      : isError
      ? IconErrorModal
      : IconConfirmModal;
  }, [isSuccess, isError]);

  return (
    <Modal
      width={438}
      closeIcon={false}
      centered
      footer={
        <div className="flex gap-4">
          {!!(showCancel && textCancel) && (
            <Button
              onClick={handleCancel}
              className="button button-outline text_14 flex-1"
            >
              {textCancel}
            </Button>
          )}
          {!!(showConfirm && textConfirm) && (
            <Button
              type={"primary"}
              onClick={handleConfirm}
              className="button text_14 flex-1"
            >
              {textConfirm}
            </Button>
          )}
        </div>
      }
      onCancel={handleCancel}
      {...rest}
    >
      <div className={cn("flex items-center flex-col")}>
        {showIcon && (
          <Icon
            component={() => <IconComponent className={""} />}
            className="mb-4"
          />
        )}

        <p className="text_18-bold break-words-util whitespace-pre-line text-center">
          {title}
        </p>
        <div
          className={cn(
            "break-words-util text_14 mb-5 mt-2 whitespace-pre-line text-center",
            !children && "mt-0",
            classNameChildren
          )}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
}

export const GlobalModalMessage = () => {
  const { modalProps } = useModal();

  return <ModalMessage {...modalProps} />;
};
