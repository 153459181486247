import { IResponseStaff } from "interfaces/staff";
import { create } from "zustand";

interface IProfileState {
  profile: IResponseStaff;
  setProfile: (data: any) => void;
}

const useProfile = create<IProfileState>()((set) => ({
  profile: {} as IResponseStaff,
  setProfile: (data) => set({ profile: data }),
}));

export default useProfile;
