import { Empty } from "antd";
import { useTranslation } from "react-i18next";

const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 rounded-[4px] bg-white p-6">
      <Empty description={<p className="">{t("common.noData")}</p>} />
    </div>
  );
};

export default NoData;
