import CommonSelect from "components/CommonSelect/CommonSelect";
import InputSearch from "components/SearchHelper/InputSearch";
import { CommonValue, UserType } from "constants/enums";
import { IFilter } from "constants/interfaces";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  filter: IFilter;
  handleSearch: any;
  children?: ReactNode;
  hasFilterStatus?: boolean;
}

export default function LiveStreamingFilter({
  handleSearch,
  children,
}: IProps) {
  const [t] = useTranslation();

  const handleChangeRole = (value: any, option: any) => {
    if (value === CommonValue.ALL) {
      handleSearch.paramsSearch({
        userType: [UserType.ORGANIZATION, UserType.STREAMER],
      });
      return;
    }
    handleSearch.paramsSearch({
      userType: [value],
    });
  };

  return (
    <div className="flex items-center gap-2 flex-wrap">
      <InputSearch
        onSearchKeyword={handleSearch.keywordSearch}
        placeholder={t("placeholders.search")}
      />
      <CommonSelect
        defaultValue={CommonValue.ALL}
        placeholder={t("common.type")}
        className="select select-search w-[200px]"
        onChange={handleChangeRole}
        options={[
          {
            value: CommonValue.ALL,
            label: t("common.all"),
          },
          {
            value: UserType.STREAMER,
            label: t("userType.liveStreaming.streamer"),
          },
          {
            value: UserType.ORGANIZATION,
            label: t("userType.liveStreaming.organization"),
          },
        ]}
      />
      {children}
    </div>
  );
}
