import { Select, SelectProps } from "antd";
import { IconCaretDown } from "assets/icons";

interface IProps extends SelectProps {}

const CommonSelect = (props: IProps) => {
  return (
    <Select
      {...props}
      suffixIcon={<IconCaretDown className="text-primary" />}
    />
  );
};

export default CommonSelect;
