import { ReactNode, createContext, useContext, useReducer } from "react";

const PermissionsContext = createContext(null);
const PermissionsDispatchContext = createContext<any>(null);

interface IProps {
  children: ReactNode;
}
function permissionReducer(permissions: any, action: any) {
  switch (action.type) {
    case "update": {
      return action.data;
    }
  }
}

export const PermissionsProvider = ({ children }: IProps) => {
  const [permissions, dispatch] = useReducer(permissionReducer, []);

  return (
    <PermissionsContext.Provider value={permissions}>
      <PermissionsDispatchContext.Provider value={dispatch}>
        {children}
      </PermissionsDispatchContext.Provider>
    </PermissionsContext.Provider>
  );
};

export const usePermissions = (): any => {
  return useContext(PermissionsContext);
};

export const useDispatchPermission = () => {
  return useContext(PermissionsDispatchContext);
};
