const routePaths = {
  dashboard: "/dashboard",
  liveStreaming: "/live-streamings",
  user: {
    list: "/users",
    detail: "/users",
  },
  streamer: {
    list: "/streamers",
    detail: "/streamers",
    request: "/streamers/request",
    requestDetail: (id: string | number) => `/streamers/request/${id}`,
    requestDowngrade: "/streamers/request-downgrade",
    requestDowngradeDetail: (id: string | number) =>
      `/streamers/request-downgrade/${id}`,
  },
  subscription: {
    animaru: "/subscription/animaru",
    streamer: "/subscription/streamer",
  },
  sns: "/sns",
  recruitment: "/recruitment",
  revenue: "/revenue",
  report: {
    liveStream: "/report/live-stream",
    sns: "/report/sns",
    recruitment: "/report/recruitment",
    review: "/report/review",
  },
  notification: "/notification",
  staff: "/staff",
  contact: "/contact",
  masterData: {
    rateExchange: "/master-data/rate-exchange",
    category: "/master-data/category",
    gift: "/master-data/gift",
    banner: "/master-data/banner",
    bank: "/master-data/bank",
  },
  staticPage: {
    terms: "/static-page/terms",
    policy: "/static-page/policy",
  },
  chat: {
    list: "/conversations",
    detail: "/conversations",
  },
  forgotPassword: "/forgot-password",
  login: "/login",
  coinHistory: "/coin-history",
  changePassword: "/change-password",
  mypage: "/mypage",
};

export default routePaths;
