import { Image, Tooltip } from "antd";
import { images } from "assets/index";
import { ReactNode } from "react";
import { cn } from "utils/cn";

interface IProps {
  avatar?: string;
  username?: string;
  email?: string;
  className?: string;
  children?: ReactNode;
}

const DisplayUserTable = ({
  avatar,
  username,
  children,
  email,
  className,
}: IProps) => {
  return (
    <div className={cn("flex items-center gap-2", className)}>
      <img
        src={avatar || images.defaultAvatar}
        alt=""
        className="h-[30px] min-w-[30px] min-h-[30px] !w-[30px] object-cover rounded-full bg-primary"
      />
      <div>
        <p className="break-all">{username}</p>
        {!!email && (
          <Tooltip title={email}>
            <p className="text-grey-400 line-clamp-2">{email}</p>
          </Tooltip>
        )}

        {children}
      </div>
    </div>
  );
};

export default DisplayUserTable;
