import Icon from "@ant-design/icons";
import { Menu } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  IconChat,
  IconCoinHistory,
  IconContact,
  IconDocument,
  IconElementEqual,
  IconGraph,
  IconGroupUser,
  IconGrowth,
  IconLiveStream,
  IconNotification,
  IconPlay,
  IconProfile,
  IconReport,
  IconSubscribe,
  IconVerified,
  IconWebsite,
} from "assets/icons";
import { usePermissions } from "utils/hooks/usePermissions";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import routePaths from "utils/routePaths";
import styles from "./styles.module.scss";
import { ItemType } from "antd/es/menu/hooks/useItems";
import useProfile from "utils/hooks/useProfile";
import configs from "constants/config";

const getVisibleItem = (item: any) => {
  return item.filter((item: any) => {
    return item.visible;
  });
};

export default function SideNav() {
  const location = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { collapsed } = useToggleSideNav();
  const [selectedKey, setSelectedKey] = useState<string>("");
  const profile = useProfile((state) => state.profile);

  const items: ItemType[] = useMemo(() => {
    return [
      {
        label: <Link to={routePaths.dashboard}>{t("nav.dashboard")}</Link>,
        key: "dashboard-nav",
        icon: <Icon component={() => <IconGraph />} />,
        url: routePaths.dashboard,
        visible: true,
      },
      {
        label: (
          <Link to={routePaths.liveStreaming}>
            {t("nav.liveStreamingManagement")}
          </Link>
        ),
        key: "live-streaming-nav",
        icon: <Icon component={() => <IconPlay />} />,
        url: routePaths.liveStreaming,
        visible: true,
      },
      {
        label: <Link to={routePaths.user.list}>{t("nav.userManagement")}</Link>,
        key: "user-nav",
        icon: <Icon component={() => <IconProfile />} />,
        url: routePaths.user.list,
        visible: true,
      },

      {
        label: t("nav.streamerManagement"),
        key: "streamers-nav",
        icon: <Icon component={() => <IconLiveStream />} />,
        visible: true,
        children: [
          {
            label: (
              <Link to={routePaths.streamer.list}>
                {t("nav.streamer.list")}
              </Link>
            ),
            key: "streamers",
            url: routePaths.streamer.list,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.streamer.request}>
                {t("nav.streamer.request")}
              </Link>
            ),
            key: "request-streamers",
            url: routePaths.streamer.request,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.streamer.requestDowngrade}>
                {t("nav.streamer.downgrade")}
              </Link>
            ),
            key: "downgrade",
            url: routePaths.streamer.requestDowngrade,
            visible: true,
          },
        ],
      },
      {
        label: t("nav.subscriptionManagement"),
        key: "subscription-nav",
        icon: <Icon component={() => <IconSubscribe />} />,
        visible: true,
        children: [
          {
            label: (
              <Link to={routePaths.subscription.animaru}>
                {t("nav.subscribe.animaru")}
              </Link>
            ),
            key: "subscribe-animaru",
            url: routePaths.subscription.animaru,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.subscription.streamer}>
                {t("nav.subscribe.streamer")}
              </Link>
            ),
            key: "subscribe-streamer",
            url: routePaths.subscription.streamer,
            visible: true,
          },
        ],
      },
      {
        label: <Link to={routePaths.sns}>{t("nav.snsManagement")}</Link>,
        key: "sns-nav",
        icon: <Icon component={() => <IconDocument />} />,
        url: routePaths.sns,
        visible: true,
      },
      {
        label: (
          <Link to={routePaths.recruitment}>
            {t("nav.recruitmentManagement")}
          </Link>
        ),
        key: "recruitment-nav",
        icon: <Icon component={() => <IconVerified />} />,
        url: routePaths.recruitment,
        visible: true,
      },
      {
        label: (
          <Link to={routePaths.revenue}>{t("nav.revenueManagement")}</Link>
        ),
        key: "revenue-nav",
        icon: <Icon component={() => <IconGrowth />} />,
        url: routePaths.revenue,
        visible: true,
      },
      {
        label: t("nav.reportManagement.root"),
        key: "reports-nav",
        icon: <Icon component={() => <IconReport />} />,
        visible: true,
        children: [
          {
            label: (
              <Link to={routePaths.report.liveStream}>
                {t("nav.reportManagement.liveStream")}
              </Link>
            ),
            key: "report-live-stream",
            url: routePaths.report.liveStream,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.report.sns}>
                {t("nav.reportManagement.sns")}
              </Link>
            ),
            key: "report-sns",
            url: routePaths.report.sns,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.report.recruitment}>
                {t("nav.reportManagement.recruitment")}
              </Link>
            ),
            key: "report-recruitment",
            url: routePaths.report.recruitment,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.report.review}>
                {t("nav.reportManagement.review")}
              </Link>
            ),
            key: "report-review",
            url: routePaths.report.review,
            visible: true,
          },
        ],
      },
      {
        label: (
          <Link to={routePaths.notification}>
            {t("nav.notificationManagement")}
          </Link>
        ),
        key: "notification-nav",
        icon: <Icon component={() => <IconNotification />} />,
        url: routePaths.notification,
        visible: true,
      },
      {
        label: <Link to={routePaths.coinHistory}>{t("nav.coinHistory")}</Link>,
        key: "coin-history-nav",
        icon: <Icon component={() => <IconCoinHistory />} />,
        url: routePaths.coinHistory,
        visible: true,
      },
      {
        label: <Link to={routePaths.staff}>{t("nav.staffManagement")}</Link>,
        key: "staff-nav",
        icon: <Icon component={() => <IconGroupUser />} />,
        url: routePaths.staff,
        visible: profile?.isSuperAdmin,
      },
      {
        label: <Link to={routePaths.contact}>{t("nav.contact")}</Link>,
        key: "contact-nav",
        icon: <Icon component={() => <IconContact />} />,
        url: routePaths.contact,
        visible: true,
      },
      {
        label: t("nav.masterData.root"),
        key: "master-data-nav",
        icon: <Icon component={() => <IconElementEqual />} />,
        visible: true,
        children: [
          {
            label: (
              <Link to={routePaths.masterData.rateExchange}>
                {t("nav.masterData.rateExchange")}
              </Link>
            ),
            key: "master-data-rate-exchange",
            url: routePaths.masterData.rateExchange,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.masterData.category}>
                {t("nav.masterData.category")}
              </Link>
            ),
            key: "master-data-category",
            url: routePaths.masterData.category,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.masterData.gift}>
                {t("nav.masterData.gift")}
              </Link>
            ),
            key: "master-data-gift",
            url: routePaths.masterData.gift,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.masterData.banner}>
                {t("nav.masterData.banner")}
              </Link>
            ),
            key: "master-data-banner",
            url: routePaths.masterData.banner,
            visible: true,
          },
          // {
          //   label: (
          //     <Link to={routePaths.masterData.bank}>
          //       {t("nav.masterData.bank")}
          //     </Link>
          //   ),
          //   key: "master-data-bank",
          //   url: routePaths.masterData.bank,
          //   visible: true,
          // },
        ],
      },
      {
        label: t("nav.staticPageManagement.root"),
        key: "static-page-nav",
        icon: <Icon component={() => <IconWebsite />} />,
        visible: true,
        children: [
          {
            label: (
              <Link to={routePaths.staticPage.terms}>
                {t("nav.staticPageManagement.terms")}
              </Link>
            ),
            key: "master-data-terms",
            url: routePaths.staticPage.terms,
            visible: true,
          },
          {
            label: (
              <Link to={routePaths.staticPage.policy}>
                {t("nav.staticPageManagement.policy")}
              </Link>
            ),
            key: "master-data-policy",
            url: routePaths.staticPage.policy,
            visible: true,
          },
        ],
      },
      {
        label: <Link to={routePaths.chat.list}>{t("nav.chat")}</Link>,
        key: "chat-nav",
        icon: <Icon component={() => <IconChat />} />,
        url: routePaths.chat.list,
        visible: true,
      },
    ];
  }, [t, profile]);

  useEffect(() => {
    if (
      [routePaths.changePassword, routePaths.mypage].includes(location.pathname)
    ) {
      setSelectedKey("");
    } else {
      items.forEach((item: any) => {
        if (location.pathname.startsWith(item?.url || "###")) {
          setSelectedKey(item?.key);
        }
        if (item.children) {
          item.children.forEach((childItem: any) => {
            if (location.pathname.startsWith(childItem.url || "###")) {
              setSelectedKey(childItem.key);
            }
          });
        }
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const firstVisibleRoute: any = items.find((route: any) => route.visible);
    if (location.pathname === "/" && firstVisibleRoute?.visible) {
      if (firstVisibleRoute?.children) {
        const firstChildrenVisible = firstVisibleRoute?.children?.find(
          (route: any) => route.visible
        );
        navigate(firstChildrenVisible?.url);
      } else {
        navigate(firstVisibleRoute?.url);
      }
    }
  }, [location.pathname, profile]);

  return (
    <div
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      style={{ width: collapsed ? 80 : 250, transition: "width 0.3s" }}
    >
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        defaultSelectedKeys={[]}
        className={styles.menu}
        items={getVisibleItem(items)}
      ></Menu>
    </div>
  );
}
