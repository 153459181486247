import { CommonStatus, ETypeUpload } from "constants/enums";
import { IResponsePaging, IZipCode } from "constants/interfaces";
import { IUserDetail } from "interfaces/user";
import { isArray, isEmpty, isNumber } from "lodash";
import numeral from "numeral";
import { getUserType } from "./helper";
import i18n from "i18n/i18n";
import { InfiniteData } from "@tanstack/react-query";

export const convertNumberToTimeSeparator = (initValue: number) => {
  if (isNaN(initValue) || initValue < 0) {
    return "00:00";
  }

  const firstTime = Math.floor(initValue / 60);
  const remainingTimes = initValue % 60;

  const formattedFirstTime = String(firstTime).padStart(2, "0");
  const formattedSecondTime = String(remainingTimes).padStart(2, "0");

  return `${formattedFirstTime}:${formattedSecondTime}`;
};

export const formatNumberByDivide = ({
  initValue,
  divisor,
  currency,
}: {
  initValue: number;
  divisor: number;
  currency: string;
}) => {
  return (initValue / divisor).toFixed(1) + currency;
};

export const formatAbbreviateNumber = (initValue: number) => {
  if (!isNumber(initValue) || !initValue) return "0";

  if (initValue < 1e3) {
    return initValue.toString();
  }

  if (initValue < 1e6) {
    return formatNumberByDivide({
      initValue,
      divisor: 1e3,
      currency: "k",
    });
  }

  if (initValue < 1e9) {
    return formatNumberByDivide({
      initValue,
      divisor: 1e6,
      currency: "m",
    });
  }

  return formatNumberByDivide({
    initValue,
    divisor: 1e9,
    currency: "b",
  });
};

export const formatNumber = (
  value?: number | string,
  format = "0,0",
  suffix = "",
  prefix = ""
): string => {
  return prefix + numeral(value || 0).format(format) + suffix;
};

export const formatFilesBeforeUpdate = ({
  previousFiles,
  files,
}: {
  previousFiles: any;
  files: any[];
}) => {
  // new files need to update
  const formattedFiles = files
    ?.filter((item: any) => !item?.id)
    .map((item: any) => {
      return {
        name: item?.imgPathname,
        type: item.fileType === "image" ? ETypeUpload.IMAGE : ETypeUpload.VIDEO,
      };
    });

  // don't have previous files
  if (!previousFiles || !previousFiles?.length || !isArray(previousFiles)) {
    return { newFiles: formattedFiles };
  }

  // files not change
  const currentFileIds = files
    ?.filter((item: any) => item?.id)
    ?.map((item: any) => item.id);

  // files are deleted
  const deletedFiles = previousFiles
    ?.filter((item: any) => !currentFileIds?.includes(item?.id))
    ?.map((item: any) => ({
      status: CommonStatus.INACTIVE,
      type: item?.fileType,
      id: item.id,
    }));

  return {
    newFiles: [...formattedFiles, ...deletedFiles],
  };
};

export const formatAddress = ({ data }: { data: any }) => {
  if (!data?.isJapanese) return i18n.t("common.abroad");
  const fullAddress =
    [
      data?.province?.name?.trim?.(),
      data?.district?.name?.trim?.(),
      data?.address?.trim?.(),
    ]
      .filter((itemAddress: string) => Boolean(itemAddress))
      .join(" - ") || i18n.t("common.inJapan");

  return fullAddress;
};

export const formatAddressGetProvince = ({ data }: { data: any }) => {
  if (!data?.isJapanese) return i18n.t("common.abroad");
  return data?.province?.name?.trim?.() || i18n.t("common.inJapan");
};

export const formatAddressLiveStreamItem = ({
  data,
  t,
}: {
  data: any;
  t: any;
}) => {
  if (!data?.isJapanese) return t("common.abroad");
  return data?.province?.name?.trim?.() || t("common.inJapan");
};

export const formatListMySubSns = (dataSubs: any[], subscriptions: any[]) => {
  const resultDataSubs = dataSubs;
  subscriptions?.forEach((item: any) => {
    const isInList = !!dataSubs?.find((itemData) => itemData?.id === item.id)
      ?.id;
    if (!isInList) resultDataSubs.push(item);
  });
  return resultDataSubs;
};

export const formatAddressByZipCode = (data: IZipCode) => {
  if (isEmpty(data)) return "";
  const fullAddress = [data?.kenName, data?.cityName, data?.townName]
    .filter(Boolean)
    .join(" - ");
  return fullAddress;
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return "";
  const cleanedPhoneNumber = phoneNumber?.replace(/\D/g, "");
  const formattedPhoneNumber = `${cleanedPhoneNumber.substring(
    0,
    3
  )}-${cleanedPhoneNumber.substring(3, 7)}-${cleanedPhoneNumber.substring(7)}`;

  return formattedPhoneNumber;
};

export const formatNameDisplayed = ({ profile }: { profile?: IUserDetail }) => {
  if (isEmpty(profile)) return;

  const { isUser, isAdminOrganization } = getUserType({
    userType: profile?.userType,
    organizationType: profile?.organizationType,
  });
  if (isUser) {
    return profile?.username;
  }
  if (isAdminOrganization) {
    return profile?.streamerProfile?.organizationName;
  }
  return profile?.streamerProfile?.nickname;
};

export const formatDataInfinite = (
  initData: InfiniteData<IResponsePaging<any[]>> | undefined
) => {
  return (
    initData?.pages
      ?.map((page) => page?.data)
      ?.reduce((arr, currentValue) => {
        return [...arr, ...currentValue];
      }, []) || []
  );
};

export const formatDataSummary = (dataMonth: any) => {
  for (let key in dataMonth) {
    if (dataMonth[key] === null) {
      dataMonth[key] = 0;
    }
  }
  return dataMonth;
};
