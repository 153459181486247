import { useQuery } from "@tanstack/react-query";
import { getProvincesApi, getResourcesApi } from "api/resources";
import { LocalStorageCode, QueryKey } from "constants/enums";
import { useLocalStorage } from "usehooks-ts";
import { IProvinceItem } from "constants/interfaces";

export default function useResources() {
  const [provinces, setProvinces] = useLocalStorage<IProvinceItem[]>(
    LocalStorageCode.PROVINCES,
    []
  );
  const [resources, setResources] = useLocalStorage(
    LocalStorageCode.RESOURCES,
    {}
  );

  useQuery({
    queryFn: getResourcesApi,
    queryKey: [QueryKey.GET_RESOURCES],
    keepPreviousData: true,
    onSuccess: (res: any) => {
      setResources(res);
    },
  });

  useQuery({
    queryFn: getProvincesApi,
    queryKey: [QueryKey.GET_PROVINCES],
    keepPreviousData: true,
    onSuccess: (res: any) => {
      setProvinces(res);
    },
  });

  return { resources, provinces };
}
