import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { router } from "App";
import { postLogin } from "api/auth";
import storage from "./storage";

export const useLogin = () => {
  const navigate = useNavigate();

  const { mutate: login, isLoading: loadingLogin } = useMutation(
    (payload: any) => postLogin(payload),
    {
      onSuccess: (data: any) => {
        const token = data?.data?.token;
        const refreshToken = data?.data?.refreshToken;
        if (token && refreshToken) {
          storage.setToken(token);
          storage.setRefreshToken(refreshToken);
        }
        navigate("/");
      },
    }
  );

  return { login, loadingLogin };
};

export const logoutWithNotApi = () => {
  storage.clearToken();
  storage.clearRefreshToken();
  // localStorage.clear();
  router.navigate("/login");
};
