import { message } from "antd";
import { ColumnType } from "antd/es/table";
import { getPresignedUrlApi } from "api/upload";
import axios from "axios";

import configs from "constants/config";
import {
  AdoptRecruitmentStatus,
  LiveStreamVisibleMode,
  OrganizationType,
  PostVisibleMode,
  RequestRevenueStatus,
  RevenueRequestStatus,
  SortOrderAntd,
  SortOrder,
  UserRequestStatus,
  UserRequestType,
  UserType,
  ETypeUpload,
  ConfigName,
  ContactStatus,
} from "constants/enums";
import { IFilter, IUserTypeResponse } from "constants/interfaces";
import {
  REGEX_IMG,
  REGEX_NOT_NUMBER,
  REGEX_YOUTUBE_VIDEO,
} from "constants/regex";
import i18n from "i18n/i18n";
import { ISummaryItem } from "interfaces/dashboard";
import { ILiveStreamingItem } from "interfaces/live-stream";
import { IStreamerDetail } from "interfaces/streamer";
import { IUserDetail } from "interfaces/user";
import { isEmpty } from "lodash";

export const handleErrorMessage = (error: any, needFormatError = true) => {
  message.destroy();
  message.error(needFormatError ? getErrorMessage(error) : error);
  if (configs.APP_ENV !== "prod") {
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return (
    error?.response?.data?.message ||
    error?.response?.data?.devMessage ||
    error ||
    "Something went wrong!"
  );
};

// Table
export const getIndexTable = ({
  pageIndex,
  pageSize,
  index = 0,
}: {
  pageIndex: number;
  pageSize: number;
  index?: number;
}) => {
  return (pageIndex - 1) * pageSize + index + 1;
};

export const getUserType = (data: IUserTypeResponse) => {
  // Streamer cá nhân: userType = 2
  // Admin tổ chức: userType = 3, organizationType = 1
  // Member tổ chức: userType = 3, organizationType = 2
  const { userType, organizationType } = data || {};

  const isMemberOrganization =
    userType === UserType.ORGANIZATION &&
    organizationType === OrganizationType.STAFF;
  const isAdminOrganization =
    userType === UserType.ORGANIZATION &&
    organizationType === OrganizationType.ADMIN;

  return {
    isUser: userType === UserType.USER,
    isStreamer: userType === UserType.STREAMER || isMemberOrganization,
    isStreamerPersonal: userType === UserType.STREAMER,
    isAdminOrganization,
    isMemberOrganization,
    isRoleOfOrganization: userType === UserType.ORGANIZATION,
  };
};

//Format text
export const trimText = (str?: string) => {
  if (!str) return "";
  return str.trim().replace(/\s+/gm, " ");
};

export const checkFileOrigin = (file: any) => {
  return !!file?.originFileObj;
};

export const replaceWithBr = (haiku: string) => {
  return haiku?.replace(/\n/g, "<br />");
};

export const abbreviateNumber = (value: any): string => {
  if (!Number.isFinite(value)) {
    return "";
  }
  if (value < 0) {
    return `-${abbreviateNumber(-value)}`;
  }
  if (value < 1000) {
    return value.toString();
  }
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixIndex = Math.floor(Math.log10(value) / 3);
  const shortValue = (value / 10 ** (suffixIndex * 3))
    .toFixed(2)
    .replace(/\.?0+$/, "");
  return `${shortValue}${suffixes[suffixIndex]}`;
};

export const isImage = (url: string) => {
  const isValidImage = url.match(REGEX_IMG) !== null;
  return isValidImage;
};

export const getIdVideoYoutube = (url: string) => {
  const match = url.match(REGEX_YOUTUBE_VIDEO);
  return match ? match[2] : "";
};

export const getEmbedYoutubeUrl = (url: string) => {
  const videoId = getIdVideoYoutube(url);
  return `https://www.youtube.com/embed/${videoId}`;
};

export const getUserRequestType = (requestType: UserRequestType): string => {
  switch (requestType) {
    case UserRequestType.REGISTER_STREAMER:
      return i18n.t("userRequestType.individual");
    case UserRequestType.REGISTER_ORGANIZATION:
      return i18n.t("userRequestType.organization");
    case UserRequestType.DOWNGRADE_ACCOUNT:
      return i18n.t("userRequestType.downgrade");
    default:
      // Handle the default case or throw an error for an unknown request type
      return "";
  }
};

export const getUserTypeValue = (data: IUserTypeResponse) => {
  if (isEmpty(data)) return "";
  const {
    isAdminOrganization,
    isUser,
    isMemberOrganization,
    isStreamerPersonal,
  } = getUserType(data);
  if (isUser) {
    return i18n.t("userType.user");
  }
  if (isStreamerPersonal) {
    return i18n.t("userType.streamerPersonal");
  }
  if (isMemberOrganization) {
    return i18n.t("userType.memberOrganization");
  }
  if (isAdminOrganization) {
    return i18n.t("userType.adminOrganization");
  }

  return "";
};

export const getUserTypeLiveStreaming = (item?: ILiveStreamingItem) => {
  const isAdminOrganization =
    item?.organization?.streamerId &&
    item?.streamerProfile?.streamerId === item?.organization?.streamerId;
  const isStreamerOfOrganization =
    item?.organization?.streamerId &&
    item?.streamerProfile?.streamerId !== item?.organization?.streamerId;
  const isStreamerPersonal =
    item?.streamerProfile?.streamerId && !item?.organization?.streamerId;

  if (isAdminOrganization || isStreamerOfOrganization) {
    return i18n.t("userType.liveStreaming.organization");
  }
  if (isStreamerPersonal) {
    return i18n.t("userType.liveStreaming.streamer");
  }
  return "";
};

export const getUserRequestStatus = (status: UserRequestStatus): string => {
  switch (status) {
    case UserRequestStatus.PENDING:
      return i18n.t("userRequestStatus.pending");
    case UserRequestStatus.APPROVE:
      return i18n.t("userRequestStatus.approved");
    case UserRequestStatus.REJECT:
      return i18n.t("userRequestStatus.rejected");
    default:
      // Handle the default case or throw an error for an unknown status
      return "";
  }
};

export const getNumberFromString = (initValue: string | number) => {
  if (!initValue) return "";
  return `${initValue}`.replace(REGEX_NOT_NUMBER, "");
};

export const getTextRole = ({
  profile,
}: {
  profile?: IUserDetail | IStreamerDetail;
}) => {
  if (isEmpty(profile)) return "";
  const {
    isUser,
    isStreamerPersonal,
    isAdminOrganization,
    isMemberOrganization,
  } = getUserType(profile as IUserTypeResponse);

  if (isUser) {
    // user
    return i18n.t("userType.user");
  }

  if (isAdminOrganization) {
    // admin of organization
    return i18n.t("userType.adminOrganization");
  }
  if (isMemberOrganization) {
    // member of organization
    return i18n.t("userType.memberOrganization");
  }

  if (isStreamerPersonal) {
    // streamer personal
    return i18n.t("userType.streamerPersonal");
  }

  return "";
};

export const getStatusRevenueValue = (status: RequestRevenueStatus) => {
  switch (status) {
    case RequestRevenueStatus.OPEN:
      return i18n.t("revenue.unpaid");
    case RequestRevenueStatus.RESOLVED:
      return i18n.t("revenue.paid");
    default:
      return "";
  }
};

export const getStatusContact = (status?: ContactStatus) => {
  switch (status) {
    case ContactStatus.Replied:
      return i18n.t("contact.responded");
    case ContactStatus.Pending:
      return i18n.t("contact.notRespond");
    default:
      return "";
  }
};

export const convertSortOrderAntdToSortOrderApi = (
  sortOrder?: SortOrderAntd
) => {
  switch (sortOrder) {
    case SortOrderAntd.ascend:
      return SortOrder.ASC;
    case SortOrderAntd.descend:
      return SortOrder.DESC;
    default:
      return undefined;
  }
};

const convertSortOrderApiToSortOrderAntd = (sortOrder?: SortOrder | null) => {
  switch (sortOrder) {
    case SortOrder.ASC:
      return SortOrderAntd.ascend;
    case SortOrder.DESC:
      return SortOrderAntd.descend;
    default:
      return undefined;
  }
};

export const getSortColumnProps = ({
  filter,
  columnKey,
}: {
  filter: IFilter;
  columnKey: string;
}): Partial<ColumnType<any>> => {
  return {
    sorter: (a: any, b: any) => 0,
    sortOrder:
      filter.sortBy === columnKey
        ? convertSortOrderApiToSortOrderAntd(filter.sortOrder)
        : undefined,
  };
};

export const getKeyStatusRequestRevenue = (status: RevenueRequestStatus) => {
  switch (status) {
    case RevenueRequestStatus.OPEN:
      return "common.statusApplication.pending";
    case RevenueRequestStatus.RESOLVED:
      return "streamerDetail.revenueTab.requestStatus.done";
    case RevenueRequestStatus.DELETED:
      return "streamerDetail.revenueTab.requestStatus.deleted";
    default:
      return "streamerDetail.revenueTab.requestStatus.deleted";
  }
};

export const getTextLiveStreamVisibleMode = (
  visibleMode: LiveStreamVisibleMode
) => {
  switch (visibleMode) {
    case LiveStreamVisibleMode.PUBLIC:
      return "common.visibleMode.public";
    case LiveStreamVisibleMode.SUBSCRIPTION:
      return "common.visibleMode.subscription";
    case LiveStreamVisibleMode.PRIVATE:
      return "common.visibleMode.private";

    default:
      return "common.visibleMode.deleted";
  }
};

export const getTextPostVisibleMode = (visibleMode: PostVisibleMode) => {
  switch (visibleMode) {
    case PostVisibleMode.PUBLIC:
      return "common.visibleMode.public";
    case PostVisibleMode.SUBSCRIPTION:
      return "common.visibleMode.subscription";
    case PostVisibleMode.PRIVATE:
      return "common.visibleMode.private";

    default:
      return "common.visibleMode.deleted";
  }
};

export const createImage = async (url: any, file: any) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const newFile = new File([blob], file?.name, { type: blob.type });

    return newFile;
  } catch (error) {
    handleErrorMessage(error);
  }
};

export const convertHeicToPng = async (file: File) => {
  const blob = new Blob([file], { type: file.type });
  const conversionResult = await (
    await import("heic2any")
  ).default({ blob, toType: "image/png" });
  const fileAfterProcessed = new File(
    [conversionResult as any],
    file?.name?.replace(".heic", ".png"),
    { type: "image/png" }
  );
  const urlImage = URL.createObjectURL(conversionResult as any);
  return {
    file: new File(
      [conversionResult as Blob],
      file.name.replace(/\.heic$/, ".png"),
      { type: "image/png" }
    ),
    fileAfterProcessed,
    urlImage,
  };
};

export const getKeyStatusRecruitment = (status?: AdoptRecruitmentStatus) => {
  switch (status) {
    case AdoptRecruitmentStatus.RECRUITING:
      return "recruitment.status.recruiting";
    case AdoptRecruitmentStatus.NEGOTIATE:
      return "recruitment.status.negotiate";
    case AdoptRecruitmentStatus.COMPLETE:
      return "recruitment.status.complete";
    case AdoptRecruitmentStatus.EXPIRED:
      return "recruitment.status.expired";
    default:
      return "";
  }
};

export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const randomColor = () => {
  const randomColorNumber = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColorNumber}`;
};

export const randomNumber = (min = 0, max = 100000000) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const calculatePercentageChange = (args: ISummaryItem | undefined) => {
  const { previous, current } = args || {};

  if (!previous && !current) {
    return 0;
  }
  if (!previous) {
    return 100;
  }

  if (!current) {
    return -100;
  }

  return Number((((current - previous) / previous) * 100).toFixed(2));
};

export const uploadFileImage = async (files: any) => {
  try {
    let imgPathname;
    let displayUrl;
    const file = files?.[0];
    const isUploaded = files?.[0]?.isUploaded;
    if (file && !isUploaded) {
      const presignedImages = await getPresignedUrlApi({
        name: file?.name,
        fileType: file.type?.includes("image")
          ? ETypeUpload.IMAGE
          : ETypeUpload.VIDEO,
      });
      await Promise.all(
        presignedImages?.data?.map(async (item: any, index: number) => {
          try {
            await axios({
              method: "PUT",
              url: item.uploadUrl,
              data: file,
              headers: {
                "Content-Type": file?.type,
              },
            });
          } catch (err) {
            handleErrorMessage(err);
          }
        })
      );
      imgPathname = presignedImages?.data?.[0]?.name;
      displayUrl = presignedImages?.data?.[0]?.displayUrl;
      return {
        imgPathname,
        displayUrl,
        preview: displayUrl,
        isUploaded: true,
        type: files?.[0]?.type,
      };
    }
    return file;
  } catch (error) {
    console.log("uploadFileImage -> error:", error);
  }
};

export const uploadListFileImage = async (files: any) => {
  try {
    if (files) {
      const listImages = await Promise.all(
        files.map(async (file: any) => {
          const isUploaded = file.isUploaded;
          if (isUploaded) {
            return file;
          }
          const presignedImages = await getPresignedUrlApi({
            name: file?.name,
            fileType: file.type?.includes("image")
              ? ETypeUpload.IMAGE
              : ETypeUpload.VIDEO,
          });
          await axios({
            method: "PUT",
            url: presignedImages?.data?.[0]?.uploadUrl,
            data: file,
            headers: {
              "Content-Type": file?.type,
            },
          });
          return {
            imgPathname: presignedImages?.data?.[0]?.name,
            displayUrl: presignedImages?.data?.[0]?.displayUrl,
            preview: presignedImages?.data?.[0]?.displayUrl,
            isUploaded: true,
            type: file?.type,
          };
        })
      );
      return listImages;
    }
    return files;
  } catch (error) {
    console.log("uploadListFileImage -> error:", error);
  }
};

export const getConfig = ({
  resources,
  name,
  defaultValue,
}: {
  resources: any;
  name: ConfigName;
  defaultValue?: any;
}) => {
  return (
    resources?.config?.find((item: any) => item?.name === name)?.value ||
    defaultValue
  );
};

export const formatPercent = (number?: number | string) => {
  if (!number) return "";
  return Number(number) * 100 + "%";
};
