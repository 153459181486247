import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
  FormOutlined,
  LockOutlined,
  StopOutlined,
  UndoOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

import { Button, Popconfirm } from "antd";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { AbstractTooltipProps } from "antd/lib/tooltip";
import { IconEye } from "assets/icons";
import { cn } from "utils/cn";

interface IProps {
  onClick?: (data?: any) => any;
  textConfirm?: string;
  className?: string;
  classNameIcon?: string;
  disabled?: boolean;
  children?: ReactNode;
}
interface IPropsCustomConfirm extends AbstractTooltipProps {
  onClick?: (data?: any) => any;
  textConfirm?: string;
  className?: string;
  classNameIcon?: string;
  disabled?: boolean;
  children?: ReactNode;
}

interface IDeleteProps {
  onDelete: (data: any) => any;
  textConfirm?: string;
  className?: string;
  classNameIcon?: string;
}

function CustomButton({ onClick, className, children }: IProps) {
  return (
    <Button
      type="text"
      className={classNames("p-1 flex", className)}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      {children}
    </Button>
  );
}

function CustomConfirm({
  textConfirm,
  onClick,
  className,
  children,
  placement = "topLeft",
  ...rest
}: IPropsCustomConfirm) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      {...rest}
      placement={placement}
      title={textConfirm}
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      cancelText={t("button.cancel")}
    >
      <Button
        type="text"
        className={classNames("p-1 flex", className)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {children}
      </Button>
    </Popconfirm>
  );
}

function Unlock({ onClick, className, classNameIcon }: IProps) {
  return (
    <Button
      type="text"
      className={classNames("p-1 flex", className)}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      <UnlockOutlined className={classNames([styles.icon, classNameIcon])} />
    </Button>
  );
}

function Edit({ onClick, className, disabled, classNameIcon }: IProps) {
  return (
    <Button
      disabled={disabled ? disabled : false}
      type="text"
      className={classNames("p-1 flex", className)}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      <FormOutlined className={classNames([styles.icon, classNameIcon])} />
    </Button>
  );
}

function Delete({
  textConfirm,
  onDelete,
  className,
  classNameIcon,
}: IDeleteProps) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onDelete(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      cancelText={t("button.cancel")}
    >
      <Button
        type="text"
        className={classNames("p-1 flex", className)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <DeleteOutlined className={classNames([styles.icon, classNameIcon])} />
      </Button>
    </Popconfirm>
  );
}

function Detail({ onClick, className, classNameIcon }: IProps) {
  return (
    <Button
      type="text"
      className={classNames("p-1 flex", className)}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      <IconEye className={cn(classNameIcon)} />
    </Button>
  );
}

function Disabled({
  textConfirm = "こちらを消除してもよろしいでしょうか？",
  onClick,
  className,
  classNameIcon,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      cancelText={t("button.cancel")}
    >
      <Button
        type="text"
        className={classNames("p-1 flex", className)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <StopOutlined
          className={classNames([styles.icon__detail, classNameIcon])}
        />
      </Button>
    </Popconfirm>
  );
}

function Block({ textConfirm, onClick, className, classNameIcon }: IProps) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      cancelText={t("button.cancel")}
    >
      <Button
        type="text"
        className={classNames("p-1 flex", className)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <UnlockOutlined
          className={classNames([styles.icon__detail_block, classNameIcon])}
        />
      </Button>
    </Popconfirm>
  );
}

function UnBlock({ textConfirm, onClick, className, classNameIcon }: IProps) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      cancelText={t("button.cancel")}
    >
      <Button
        type="text"
        className={classNames("p-1 flex", className)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <LockOutlined
          className={classNames([styles.icon__detail_block, classNameIcon])}
        />
      </Button>
    </Popconfirm>
  );
}

function Copy({ onClick, className, classNameIcon }: IProps) {
  return (
    <Button
      type="text"
      className={classNames("p-1 flex justify-center items-center", className)}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      <CopyOutlined
        className={classNames([styles.icon__detail, classNameIcon])}
      />
    </Button>
  );
}

function Undo({ onClick, className, textConfirm, classNameIcon }: IProps) {
  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button
        type="text"
        className={classNames(
          "p-1  flex justify-center items-center",
          className
        )}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <UndoOutlined className={classNames([styles.icon, classNameIcon])} />
      </Button>
    </Popconfirm>
  );
}

function Approve({ onClick, className }: IProps) {
  return (
    <Button
      type="text"
      className={classNames("p-1 flex justify-center items-center", className)}
      onClick={(e: any) => {
        onClick?.(e);
      }}
    >
      <CheckOutlined className={styles.icon} style={{ color: "green" }} />
    </Button>
  );
}

const ConfirmButton = ({
  onClick,
  className,
  title,
  textConfirm,
  children,
  ...restProps
}: any) => {
  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button
        type="primary"
        className={cn(styles.actionButton, className)}
        {...restProps}
      >
        {title || children}
      </Button>
    </Popconfirm>
  );
};

export {
  Unlock,
  Edit,
  Delete,
  Detail,
  Disabled,
  Block,
  UnBlock,
  Copy,
  Undo,
  Approve,
  CustomConfirm,
  ConfirmButton,
  CustomButton,
};
