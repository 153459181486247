export const MIN_LENGTH_PASSWORD = 8;

export const MAX_LENGTH_PASSWORD = 20;

export const MAX_LENGTH_USERNAME = 50;

export const MAX_IMAGE_POST_COMMUNITY = 4;

export const DEFAULT_FILTER = {
  pageIndex: 1,
  pageSize: 10,
};

export const REGEX_HTML_TAGS = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
