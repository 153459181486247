import { CommonStatus, NotificationType } from "constants/enums";
import { sendDelete, sendGet, sendPost, sendPut } from "./axios";
import { IFilter, IResponse, IResponsePaging } from "constants/interfaces";
import {
  INotificationReceivedItem,
  IResponseDetailNotification,
  IResponseNotification,
} from "interfaces/notification";

interface IParamsNotification {
  keyword?: string;
  type?: NotificationType;
  pageIndex: number;
  pageSize: number;
}

export const getListNotificationAPI = (
  query: IParamsNotification
): Promise<IResponsePaging<IResponseNotification>> =>
  sendGet("/cms/notification", query);

export interface IParamsCreateNotification {
  title: string;
  content: string;
  userIds?: number[];
  receiverType?: number[];
  type: number;
}

export const createNotificationApi = (params: IParamsCreateNotification) =>
  sendPost("/cms/notification", params);

export const getDetailNotificationApi = (
  id: number
): Promise<IResponseDetailNotification> => sendGet(`/cms/notification/${id}`);

export const getTotalUnreadNotificationApi = (): Promise<IResponse<any>> =>
  sendGet(`/cms/notification/total-unread`);

export const getListNotificationReceivedApi = (
  params?: IFilter
): Promise<IResponsePaging<INotificationReceivedItem[]>> =>
  sendGet("/cms/notification/list-received", params);

export const getDetailNotificationReceivedApi = (
  notificationId: number
): Promise<IResponsePaging<INotificationReceivedItem>> =>
  sendGet(`/cms/notification/notification-received/${notificationId}`);

export const readNotificationApi = (notificationId: number): Promise<any> =>
  sendPost(`/cms/notification/read-notification/${notificationId}`);
