import {
  CommonStatus,
  ConfigName,
  GiftType,
  ResourceType,
  SortOrder,
} from "constants/enums";
import {
  IFilter,
  IFilterListGift,
  IGiftItem,
  IProvinceItem,
  IResponse,
  IResponsePaging,
} from "constants/interfaces";
import { ICategoryItem } from "interfaces/resource";
import { sendGet, sendPost, sendPut } from "./axios";

export interface IPayloadExchangeRateResources {
  exchangeRates: {
    name:
      | ConfigName.COIN_TO_YEN
      | ConfigName.DIAMOND_TO_YEN
      | ConfigName.DIAMOND_TO_COIN;
    value: number;
  }[];
}

export interface IFilterListCategories extends IFilter {
  sortOrder?: SortOrder;
}

export interface IPayloadModifyCategory {
  name?: string;
  url?: string;
  order?: number;
}

export interface IPayloadEditCategory extends IPayloadModifyCategory {
  status?: CommonStatus;
}

export interface IPayloadModifyGift {
  name?: string;
  type?: GiftType;
  price?: number;
  url?: string;
  urlGif?: string;
  categoryId?: number;
  order?: number;
}

export interface IPayloadEditGift extends IPayloadModifyGift {
  status?: CommonStatus;
}

export interface IPayloadUpdateResource {
  name?: string;
  value: string;
  order?: number;
  type: ResourceType;
  status?: number;
}

export const getResourcesApi = () =>
  sendGet("/resource").then((data: any) => data.data);

export const updateResourceApi = ({
  resourceId,
  payload,
}: {
  resourceId: number | string;
  payload: IPayloadUpdateResource;
}): Promise<IResponse<boolean>> =>
  sendPut(`/cms/resource/${resourceId}`, payload);

export const getProvincesApi = (): Promise<IProvinceItem[]> =>
  sendGet("/address/provinces").then((data: any) => data.data);

export const updateExchangeRateResources = (
  payload: IPayloadExchangeRateResources
): Promise<IResponse<boolean>> =>
  sendPut(`/cms/resource/update-exchange-rate`, payload);

export const getListCategoriesApi = (
  params: IFilterListCategories
): Promise<IResponsePaging<ICategoryItem[]>> =>
  sendGet(`/cms/resource/list-category`, params);

export const createCategoryApi = (
  payload: IPayloadModifyCategory
): Promise<IResponse<boolean>> =>
  sendPost(`/cms/resource/create-category`, payload);

export const updateCategoryApi = ({
  categoryId,
  payload,
}: {
  categoryId: number;
  payload: IPayloadEditCategory;
}): Promise<IResponse<boolean>> =>
  sendPut(`/cms/resource/update-category/${categoryId}`, payload);

export const getListGiftApi = (
  params: IFilterListGift
): Promise<IResponsePaging<IGiftItem[]>> =>
  sendGet(`/cms/resource/list-gift`, params);

export const createGiftApi = (
  payload: IPayloadModifyGift
): Promise<IResponse<boolean>> =>
  sendPost(`/cms/resource/create-gift`, payload);

export const updateGiftApi = ({
  giftId,
  payload,
}: {
  giftId: number;
  payload: IPayloadEditGift;
}): Promise<IResponse<boolean>> =>
  sendPut(`/cms/resource/update-gift/${giftId}`, payload);
