import Icon from "@ant-design/icons";
import { Select, SelectProps, Image, Spin } from "antd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { cn } from "utils/cn";
import { QueryKey } from "constants/enums";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { DEFAULT_FILTER } from "constants/defaultValues";
import useFilter from "utils/hooks/useFilter";
import { getListNotificationReceivedApi } from "api/notifications";
import { useEffect, useMemo } from "react";
import { formatDataInfinite } from "utils/format";
import NoData from "components/NoData";
import ItemNotificationReceived from "./ItemNotificationReceived";
import { INotificationReceivedItem } from "interfaces/notification";

interface IProps {
  className?: string;
  setOpenNotification?: (open: boolean) => void;
}

function ListNotificationReceived(props: IProps) {
  const { className, setOpenNotification } = props;
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { filter, handleSearch } = useFilter({
    pageSize: DEFAULT_FILTER.pageSize,
  });

  const {
    data: listData,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [QueryKey.LIST_NOTIFICATION_RECEIVED, filter],
    queryFn: ({ pageParam = 1 }) =>
      getListNotificationReceivedApi({
        ...filter,
        pageIndex: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return (lastPage?.pageIndex || 0) + 1;
    },
  });

  const listDataFormatted = useMemo(() => {
    return formatDataInfinite(listData);
  }, [listData]);

  useEffect(() => {
    if (!listData?.pages?.length) return;
    if (inView && listData?.pages?.[listData?.pages?.length - 1]?.hasNextPage) {
      fetchNextPage?.();
    }
  }, [inView]);

  return (
    <div
      className={cn(
        "bg-white rounded-[4px] w-[500px] md:w-[600px] max-h-[700px] min-h-[177px] common-shadow overflow-auto scrollbar-thin flex flex-col",
        className
      )}
    >
      {isLoading ? (
        <div className="h-full w-full flex items-center justify-center my-auto">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!listDataFormatted?.length && <NoData />}
          {listDataFormatted?.map((item: INotificationReceivedItem) => {
            return (
              <ItemNotificationReceived
                item={item}
                setOpenNotification={setOpenNotification}
              />
            );
          })}
          <div className="min-h-[1px]" ref={ref} />
        </>
      )}
    </div>
  );
}

export default ListNotificationReceived;
