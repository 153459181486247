import { IDefaultSearchParams, IResponsePaging } from "constants/interfaces";
import { sendDelete, sendGet, sendPost, sendPut } from "./axios";
import { IResponseStaff } from "interfaces/staff";

interface IParamsListStaffs extends IDefaultSearchParams {
  status?: number;
}

export const getListStaffsApi = (
  params: IParamsListStaffs
): Promise<IResponsePaging<IResponseStaff[]>> =>
  sendGet("/cms/staff/list", params);

interface IParamsUpdateStaff {
  staffId: number;
  status?: number;
  username?: string;
}

export const updateStaffApi = (params: IParamsUpdateStaff) =>
  sendPut("/cms/staff/update-staff", params);

interface IParamsCreateStaff {
  username: string;
  email: string;
}

export const createStaffApi = (params: IParamsCreateStaff) =>
  sendPost("/cms/staff", params);

export const deleteStaffApi = (id: number) => sendDelete(`/cms/staff/${id}`);

export const getProfileApi = (): Promise<{ data: IResponseStaff }> =>
  sendGet("/cms/staff/profile");

interface IParamsChangePassword {
  password: string;
  newPassword: string;
}

export const changePasswordApi = (params: IParamsChangePassword) =>
  sendPut(`/cms/staff/change-password`, params);

interface IParamsUpdateProfile {
  username?: string;
  phone?: string;
  avatar?: string;
}

export const updateProfileApi = (params: IParamsUpdateProfile) =>
  sendPut("/cms/staff/profile", params);
