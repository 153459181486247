import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ConfigProvider } from "antd";

import RootWrapper from "wrappers/RootWrapper";
import { PermissionsProvider } from "utils/hooks/usePermissions";
import ja from "dayjs/locale/ja";
import dayjs from "dayjs";
import jaJP from "antd/locale/ja_JP";
import { GlobalModalMessage } from "components/Modal/ModalMessage";
import useOneSignal from "onesignal/useOneSignal";
import { SocketProvider } from "utils/hooks/useSocket";
dayjs.locale({ ...ja, weekStart: 1 });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000,
      retry: false,
    },
  },
});

export const router = createBrowserRouter([
  // match everything with "*"
  { path: "*", element: <RootWrapper /> },
]);

export default function App() {
  useOneSignal();

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={jaJP}
        autoInsertSpaceInButton={false}
        theme={{
          token: {
            colorPrimary: "#39905C",
            controlInteractiveSize: 20,
            colorTextPlaceholder: "#818181",
            lineHeight: 1.5,
            colorBorder: "#B9E1C5",
            borderRadius: 8,
            colorText: "#2E2E2E",
          },
          components: {
            Radio: {
              radioSize: 20,
              dotSize: 10,
            },
            Input: {
              inputFontSize: 16,
              paddingBlock: 15,
              paddingBlockSM: 11,
              activeBg: "#DBF0E0",
              hoverBg: "#DBF0E0",
            },
            Select: {
              optionFontSize: 16,
            },
          },
        }}
      >
        <PermissionsProvider>
          <SocketProvider>
            <React.Suspense fallback={null}>
              <GlobalModalMessage />
              <RouterProvider router={router} />
            </React.Suspense>
          </SocketProvider>
        </PermissionsProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}
