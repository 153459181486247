import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Row, Tooltip, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { starLiveStreamApi, stopLiveStreamApi } from "api/live-stream";
import {
  IconComment,
  IconEye,
  IconStarLive,
  IconStarLiveOutline,
  IconStop,
} from "assets/icons";
import {
  CustomButton,
  CustomConfirm,
} from "components/ActionTable/ActionTable";
import CustomTable from "components/CustomTable";
import DisplayUserTable from "components/DisplayUserTable/DisplayUserTable";
import configs from "constants/config";
import { QueryKey } from "constants/enums";
import { ILiveStreamingItem } from "interfaces/live-stream";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatAbbreviateNumber } from "utils/format";
import {
  getIndexTable,
  getUserTypeLiveStreaming,
  handleErrorMessage,
} from "utils/helper";

interface IProps {
  data: ILiveStreamingItem[] | undefined;
  isLoading: boolean;
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  onPageChange: (pageIndex: number, pageSize: number) => void;
}

export default function LiveStreamingTable({
  data,
  isLoading,
  pageIndex,
  pageSize,
  totalItems,
  onPageChange,
}: IProps) {
  const [t] = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: mutateStopLiveStream } = useMutation({
    mutationFn: stopLiveStreamApi,
    onSuccess: () => {
      message.success(t("message.success"));
      queryClient.invalidateQueries([QueryKey.LIST_LIVE_STREAMING]);
    },
    onError: (err) => handleErrorMessage(err),
  });

  const { mutate: mutateStarLiveStream } = useMutation({
    mutationFn: starLiveStreamApi,
    onSuccess: () => {
      message.success(t("message.success"));
      queryClient.invalidateQueries([QueryKey.LIST_LIVE_STREAMING]);
    },
    onError: (err) => handleErrorMessage(err),
  });

  const columns: ColumnsType<any> = [
    {
      title: t("field.index"),
      dataIndex: "id",
      key: "id",
      width: 50,
      align: "center",
      render: (text: string, record: any, index: number) => (
        <p className="min-w-[50px]">
          {getIndexTable({ pageIndex, pageSize, index })}
        </p>
      ),
    },
    {
      title: t("common.username"),
      key: "username",
      width: 250,
      render: (value, record: ILiveStreamingItem) => (
        <DisplayUserTable
          avatar={record?.user?.avatar}
          username={record?.user?.username}
          email={record?.user?.email}
          className="min-w-[250px]"
        />
      ),
    },
    {
      title: t("common.nickname"),
      dataIndex: "nickname",
      key: "nickname",
      width: 200,
      render: (value, record: ILiveStreamingItem) => (
        <Tooltip title={record?.streamerProfile?.nickname}>
          <p className="min-w-[200px] line-clamp-2">
            {record?.streamerProfile?.nickname}
          </p>
        </Tooltip>
      ),
    },
    {
      title: t("common.type"),
      dataIndex: "type",
      key: "type",
      width: 80,
      render: (value: string, record: ILiveStreamingItem) => (
        <p className="min-w-[80px]">{getUserTypeLiveStreaming(record)}</p>
      ),
    },
    {
      title: t("common.organization"),
      dataIndex: "organization",
      key: "organization",
      width: 120,
      render: (value, record: ILiveStreamingItem, index) => (
        <p className="min-w-[120px]">
          {record?.organization?.organizationName}
        </p>
      ),
    },
    {
      title: t("field.totalViewer"),
      dataIndex: "viewCount",
      key: "viewCount",
      width: 100,
      render: (value: number) => {
        return (
          <Row
            justify={"start"}
            align={"middle"}
            className="gap-1 min-w-[100px]"
          >
            <IconEye className="text-grey-400" />
            <p>{formatAbbreviateNumber(value)}</p>
          </Row>
        );
      },
    },
    {
      title: t("field.totalComment"),
      dataIndex: "totalComments",
      key: "totalComments",
      width: 100,
      render: (value: number) => {
        return (
          <Row
            justify={"start"}
            align={"middle"}
            className="gap-1 min-w-[100px]"
          >
            <IconComment />
            <p>{formatAbbreviateNumber(value)}</p>
          </Row>
        );
      },
    },
    {
      title: t("field.kpi"),
      key: "kpi",
      width: 80,
      render: (record: ILiveStreamingItem) => {
        return (
          <p className="min-w-[80px]">{`${record?.totalLike || 0}/${
            record?.streamerProfile?.kpi || 0
          }`}</p>
        );
      },
    },
    {
      title: t("common.link"),
      dataIndex: "link",
      key: "link",
      width: 80,
      render: (value: any, record: ILiveStreamingItem) => {
        return (
          <div className=" min-w-[80px]">
            <Link
              target="_blank"
              className="text-link-blue underline"
              to={queryString.stringifyUrl({
                url: `${configs.WEB_USER_DOMAIN}/live`,
                query: {
                  liveStreamId: record?.id,
                  streamerId: record?.streamerProfile?.streamerId,
                },
              })}
            >
              {t("common.linkLive")}
            </Link>
          </div>
        );
      },
    },
    {
      title: t("field.action"),
      key: "action",
      align: "center",
      width: 120,
      render: (record: ILiveStreamingItem, index: number) => {
        return (
          <Row
            align={"middle"}
            justify={"center"}
            className="gap-x-8  min-w-[120px] flex-nowrap"
          >
            <CustomButton
              onClick={() =>
                mutateStarLiveStream({
                  liveStreamId: record.id,
                  payload: {
                    status: Number(!record?.isStar),
                  },
                })
              }
            >
              <Tooltip
                title={t("liveStreaming.pushLiveStream")}
                // mouseLeaveDelay={0}
              >
                {record?.isStar ? <IconStarLive /> : <IconStarLiveOutline />}
              </Tooltip>
            </CustomButton>
            <CustomConfirm
              placement="bottomLeft"
              textConfirm={t("liveStreaming.confirmStop")}
              onClick={() => mutateStopLiveStream(record.id)}
            >
              <Tooltip
                title={t("liveStreaming.stop")}
                //  mouseLeaveDelay={0}
              >
                <IconStop />
              </Tooltip>
            </CustomConfirm>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      {/* {(loadingAcceptDoctor ||
        loadingRejectDoctor ||
        loadingDisableDoctor ||
        loadingEnableDoctor) && <Loading />} */}
      <CustomTable
        columns={columns}
        dataSource={data || []}
        isLoading={isLoading}
        onPageChange={onPageChange}
        pageIndex={pageIndex}
        totalItems={Number(totalItems)}
      />
    </>
  );
}
