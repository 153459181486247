import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { readNotificationApi } from "api/notifications";
import { QueryKey, ReadNotification } from "constants/enums";
import { isNil } from "lodash";

const useReadNotification = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const notificationId = searchParams.get("notificationId");

  const { mutate: readNotification } = useMutation({
    mutationFn: readNotificationApi,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.TOTAL_UNREAD_NOTIFICATION],
      });
    },
  });

  useEffect(() => {
    const isRead = searchParams.get("isRead");
    const isNotHaveIsRead = isNil(isRead) || isRead === "";

    if (!notificationId) return;
    if (Number(isRead) === ReadNotification.UNREAD || isNotHaveIsRead) {
      readNotification(Number(notificationId));
    }
  }, [notificationId]);
};

export default useReadNotification;
