export enum TokenType {
  TOKEN = "token",
  REFRESH_TOKEN = "refreshToken",
}

export enum ErrorCode {
  UNAUTHORIZED = "Unauthorized",
  Contact_Not_Found = "Contact_Not_Found",
}

export enum ResponseStatus {
  NOT_FOUND = 404,
  FORBIDDEN = 403,
}

export enum DebounceTime {
  DEFAULT = 300,
}

export enum LanguageType {
  JA = "ja",
  EN = "en",
  KEY = "cimode",
  VI = "vi",
  KO = "ko",
}

export enum LocalStorageCode {
  I18 = "i18nextLng",
  RESOURCES = "resources",
  PROVINCES = "provinces",
}

export enum QueryKey {
  // PROFILE
  GET_PROFILE = "GET_PROFILE",

  // RESOURCE
  GET_RESOURCES = "GET_RESOURCES",
  GET_PROVINCES = "GET_PROVINCES",

  // OTHER
  LIST_STREAMER = "LIST_STREAMER",
  LIST_REQUEST_FOR_STREAMER = "LIST_REQUEST_FOR_STREAMER",
  LIST_REQUEST_DOWNGRADE = "LIST_REQUEST_DOWNGRADE",
  LIST_LIVE_STREAMING = "LIST_LIVE_STREAMING",

  LIST_USER = "LIST_USER",
  USER_DETAIL = "USER_DETAIL",
  LIST_REQUEST_REVENUE = "LIST_REQUEST_REVENUE",
  LIST_RECRUITMENT = "LIST_RECRUITMENT",

  // SNS
  LIST_SNS = "LIST_SNS",
  LIST_POST_TYPE = "LIST_POST_TYPE",

  // NOTIFICATION
  LIST_NOTIFICATION = "LIST_NOTIFICATION",
  DETAIL_NOTIFICATION = "DETAIL_NOTIFICATION",
  TOTAL_UNREAD_NOTIFICATION = "TOTAL_UNREAD_NOTIFICATION",
  LIST_NOTIFICATION_RECEIVED = "LIST_NOTIFICATION_RECEIVED",

  // MASTER DATA
  LIST_GIFT = "LIST_GIFT",
  LIST_CATEGORY = "LIST_CATEGORY",
  LIST_BANNER = "LIST_BANNER",

  // REPORT
  LIST_REPORT_STREAMER = "LIST_REPORT_STREAMER",
  DETAIL_REPORT_STREAMER = "DETAIL_REPORT_STREAMER",
  LIST_REPORT_SNS = "LIST_REPORT_SNS",
  DETAIL_REPORT_SNS = "DETAIL_REPORT_SNS",
  LIST_REPORT_RECRUITMENT = "LIST_REPORT_RECRUITMENT",
  DETAIL_REPORT_RECRUITMENT = "DETAIL_REPORT_RECRUITMENT",
  LIST_REQUEST_DELETE_REVIEW = "LIST_REQUEST_DELETE_REVIEW",

  // STREAMER
  STREAMER_DETAIL = "STREAMER_DETAIL",
  GET_SUMMARY_REVENUE = "GET_SUMMARY_REVENUE",
  GET_SUMMARY_REVENUE_OFF_STAFF = "GET_SUMMARY_REVENUE_OFF_STAFF",
  GET_HISTORY_REQUEST_REVENUE = "GET_HISTORY_REQUEST_REVENUE",
  GET_HISTORY_EXCHANGE_COIN = "GET_HISTORY_EXCHANGE_COIN",
  GET_DETAIL_REQUEST_STREAMER = "GET_DETAIL_REQUEST_STREAMER",

  // SUBSCRIPTION
  DETAIL_SUBSCRIPTION_ANIMARU = "DETAIL_SUBSCRIPTION_ANIMARU",
  SUBSCRIPTION_SUMMARY = "SUBSCRIPTION_SUMMARY",
  LIST_SUBSCRIBE_ANIMARU = "LIST_SUBSCRIBE_ANIMARU",
  LIST_REQUEST_SUBSCRIPTION = "LIST_REQUEST_SUBSCRIPTION",
  // LIVE STREAM
  GET_LIST_LIVE_STREAM_ENDED = "GET_LIST_LIVE_STREAM_ENDED",

  // COIN HISTORY
  COIN_HISTORY = "COIN_HISTORY",

  // STAFF
  LIST_STAFF = "LIST_STAFF",

  // CONTACT
  LIST_CONTACT = "LIST_CONTACT",
  DETAIL_CONTACT = "DETAIL_CONTACT",

  // DASHBOARD
  DASHBOARD_SUMMARY = "DASHBOARD_SUMMARY",

  // CONVERSATION
  DETAIL_CONVERSATION = "DETAIL_CONVERSATION",
}

export enum PostCommunityStatus {
  ON = "ON",
  OFF = "OFF",
}

export enum RequestRevenueStatus {
  OPEN = 1,
  RESOLVED = 2,
}

export enum ActionType {
  ADD = "ADD",
  UPDATE = "UPDATE",
}

export enum CommonStatus {
  ALL = -1,
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum CommonValue {
  ALL = -1,
}

export enum UserRequestType {
  REGISTER_STREAMER = 1,
  REGISTER_ORGANIZATION = 2,
  DOWNGRADE_ACCOUNT = 3,
}

export enum FileTargetType {
  POST = 1,
  LIVESTREAM = 2,

  // Enum image register streamer
  FRONT_IDENTIFICATION_PHOTO = 3,
  BACK_IDENTIFICATION_PHOTO = 4,
  YOURSELF_AND_ID_PHOTO = 5,
  FACE_PHOTO = 6,
  ACTIVITY_CERTIFICATE_PHOTO = 7,

  // Enum image change role streamer
  SWITCH_STREAMER_ROLE_FRONT_IDENTIFICATION_PHOTO = 10,
  SWITCH_STREAMER_ROLE_BACK_IDENTIFICATION_PHOTO = 11,
  SWITCH_STREAMER_ROLE_YOURSELF_AND_ID_PHOTO = 12,
  SWITCH_STREAMER_ROLE_FACE_PHOTO = 13,
  SWITCH_STREAMER_ROLE_ACTIVITY_CERTIFICATE_PHOTO = 14,

  MESSAGE_CONVERSATION = 15,
}

export enum UserRequestStatus {
  REJECT = 0,
  APPROVE = 1,
  PENDING = 2,
}

// Streamer cá nhân: userType = 2
// Admin tổ chức: userType = 3, organizationType = 1
// Member tổ chức: userType = 3, organizationType = 2

export enum UserType {
  USER = 1,
  STREAMER = 2,
  ORGANIZATION = 3,
}

export enum UserMixOrganizationType {
  USER = UserType.USER,
  STREAMER_PERSONAL = UserType.STREAMER,
  ADMIN_ORGANIZATION = UserType.ORGANIZATION,
  MEMBER_ORGANIZATION = 4,
}

export enum OrganizationType {
  ADMIN = 1,
  STAFF = 2,
}

export enum SubscribeSystemStatus {
  NOT_SUBSCRIBED = 0,
  SUBSCRIBING = 1,
  CANCELED_SUBSCRIPTION = 2,
}

export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  ADMIN_BLOCK = 3,
  ADMIN_ORGANIZATION_BLOCK = 4,
}

export enum BankAccountType {
  ORDINARY_DEPOSIT = 1,
  CURRENT_DEPOSIT = 2,
}

export enum StreamerProfileTabs {
  BASIC = "basic",
  REVENUE = "revenue",
  LIVE_ENDED = "live-ended",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortOrderAntd {
  ascend = "ascend",
  descend = "descend",
}
export enum NotificationType {
  ALL = 1,
  SELECTED = 2,
}

export enum NotificationSendAllType {
  USER = 0,
  INDIVIDUAL = 1,
  FACILITY = 2,
}

export enum TradingTabs {
  CHANGE_COIN = "change-coin",
  REQUEST_REVENUE = "request-revenue",
}

export enum StatisticRevenueTabs {
  ORGANIZATION = "organization",
  MEMBER = "member",
}

export enum TimeUnit {
  Month = "M",
  Year = "y",
  Week = "w",
}

export enum RevenueRequestStatus {
  DELETED = 0,
  OPEN = 1,
  RESOLVED = 2,
}

export enum LiveStreamVisibleMode {
  DELETED = 0,
  PRIVATE = 1,
  PUBLIC = 2,
  SUBSCRIPTION = 3,
}

export enum PostVisibleMode {
  PUBLIC = 1,
  SUBSCRIPTION = 2,
  PRIVATE = 3,
}

export enum SiteType {
  USER = 1,
  CMS = 2,
}

export enum RoleToCreatePost {
  USER = 1,
  ORGANIZATION = 2,
  ADMIN = 3,
}

export enum ETypeUpload {
  IMAGE = 1,
  VIDEO = 2,
  ALL = -1,
}

export enum ASPECT_CROP {
  SQUARE = 1,
  RECTANGLE_VERTICAL = 153 / 205,
  RECTANGLE_HORIZONTAl = 131 / 80,
}

export enum AdoptRecruitmentStatus {
  RECRUITING = 1,
  NEGOTIATE = 2,
  COMPLETE = 3,
  EXPIRED = 4,
}

export enum GiftType {
  NORMAL = 1,
  LIMITED = 2,
}

export enum ResourceType {
  TERM = 1,
  POLICY = 2,
}

export enum AdminBlockOrganizationType {
  ALL_STAFF = 1,
  ONLY_ADMIN = 2,
}

export enum ModalMessageType {
  SUCCESS = "success",
  ERROR = "error",
  CONFIRM = "confirm",
}

export enum RangeTime {
  "DAY" = "date",
  "WEEK" = "week",
  "MONTH" = "month",
  "YEAR" = "year",
}

export enum ConfigName {
  COIN_TO_YEN = "COIN_TO_YEN",
  MAX_KPI_COMPLETED_PER_DAY = "MAX_KPI_COMPLETED_PER_DAY",
  DIAMOND_FOR_COMPLETED_KPI = "DIAMOND_FOR_COMPLETED_KPI",
  MAX_FILES_BANNER = "MAX_FILES_BANNER",
  MAX_SOS_TIME_PER_MONTH = "MAX_SOS_TIME_PER_MONTH",
  MINUTE_LIMIT_TO_END_LIVE_STREAM_NO_HOST = "MINUTE_LIMIT_TO_END_LIVE_STREAM_NO_HOST",
  MAXIMUM_NUMBER_OF_PINNED_POSTS = "MAXIMUM_NUMBER_OF_PINNED_POSTS",
  STREAMER_SUBSCRIPTION_DURATION = "STREAMER_SUBSCRIPTION_DURATION",
  MAX_SUBSCRIPTION = "MAX_SUBSCRIPTION",
  DIAMOND_TO_YEN = "DIAMOND_TO_YEN",
  MIN_DIAMOND_PER_REVENUE_REQUEST = "MIN_DIAMOND_PER_REVENUE_REQUEST",
  MAX_DIAMOND_PER_REVENUE_REQUEST = "MAX_DIAMOND_PER_REVENUE_REQUEST",
  MAX_YEN_STREAMER_REQUEST_PER_MONTH = "MAX_YEN_STREAMER_REQUEST_PER_MONTH",
  MAX_YEN_ORGANIZATION_REQUEST_PER_MONTH = "MAX_YEN_ORGANIZATION_REQUEST_PER_MONTH",
  TRANSFER_FEE = "TRANSFER_FEE",
  DIAMOND_TO_COIN = "DIAMOND_TO_COIN",
  COIN_FOR_SUBSCRIBE_SYSTEM = "COIN_FOR_SUBSCRIBE_SYSTEM",
}

export enum BannerType {
  HOME = 1,
  ADOPT_RECRUITMENT = 2,
  POST = 3,
  LIVE_STREAM = 4,
}

export enum ContactStatus {
  Pending = 1,
  Replied = 2,
  Deleted = 3,
}

export enum ConversationMessagesType {
  TEXT = 1,
  FILE = 2,
}

export enum FileType {
  IMAGE = 1,
  VIDEO = 2,
  FILE = 3,
}

export enum SocketEvent {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  SERVER_ERROR = "SERVER_ERROR",

  USER_LOGIN = "USER_LOGIN", // to prevent login multi device

  UPDATE_TOTAL_UNREAD_NOTIFICATION = "UPDATE_TOTAL_UNREAD_NOTIFICATION",
}

// Notification CMS
export enum NotificationAdminReceiveType {
  REQUEST_REGISTER_STREAMER = 1,
  REQUEST_DOWNGRADE_ACCOUNT = 2,
  NEW_CONTACT = 3,
}

export enum ReadNotification {
  READ = 1,
  UNREAD = 0,
}
