import { IBodyUrlPresign } from "constants/interfaces";
import { sendPost } from "./axios";

interface IPresignedUrl {
  fileType: number;
  name: string;
}

// upload file to s3 to get link
export const getPresignedUrlApi = (params: IPresignedUrl) =>
  sendPost<IBodyUrlPresign>("/file/create-files", params);
