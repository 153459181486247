import { CommonStatus, CommonValue } from "constants/enums";
import i18n from "i18n/i18n";

export const defaultTake = 10;
export const MAX_FILE_DEFAULT = 6;
export const MAX_FILE_SIZE = 50 * 1024 * 1024; // bytes
export const MAX_FILE_VIDEO_SIZE = 500 * 1024 * 1024; // bytes
export const MAX_LENGTH_CONTENT_SNS = 3000;
export const MAX_TERM_POLICY = 16777215;
export const MAX_LENGTH_TEXT_AREA = 1000;
export const MAX_LENGTH_CATEGORY = 20;
export const MAX_LENGTH_GIFT_NAME = 80;
export const OTP_LENGTH = 6;
export const OTP_COUNT_DOWN = 60;
export const imageTypes = ["png", "jpeg", "jpg", "gif", "jfif", "heic"];

export const videoTypes = ["mp4", "mov", "quicktime", "3gp", "mkv", "flv"];

export const text = {
  fieldIsRequired: "こちらは必須項目です。",
  twoPasswordDontMatch: "パスワードとパスワード再入力が異なっています。",
  validatePassword:
    "パスワードは8～20桁の半角文字、半角数字の2種類を組み合わせてください。",
  validateFullNameRegex: "名前には特殊文字を含むことはできません。",
  validateWhiteSpace: "こちらは必須項目です。",
};

export const componentsText = {
  importImageVideos: "動画・画像アップロード",
  imagesVideosSelection: "ファイルをアップロード",
  limitFilesCount: "写真・動画を最大10枚まで登録可能",
  videosDocumentsSelection: "ファイルをアップロード",
};

export const errorText = {
  largeFileDanger:
    "*画像の容量が過ぎました。容量5MBまでの画像をアップロードしてください。",
  wrongFormatFileDanger:
    "*Only images/videos formatted in (.png, .jpg, .jpeg, .mov, .mp4) are allowed。",
  manyFilesDanger: "*Only 10 images/videos are allowed。",
};

export const optionsDropdownStatus = [
  { value: CommonValue.ALL, label: i18n.t("common.all") },
  { value: CommonStatus.INACTIVE, label: i18n.t("common.inactive") },
  { value: CommonStatus.ACTIVE, label: i18n.t("common.active") },
];

export const acceptedAvatarType = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/heic",
  "image/jfif",
];

export const acceptedDocumentsType = ["application/pdf"];

export const acceptedVideoType = [
  "video/mp4",
  "video/mov",
  "video/quicktime",
  "video/3gp",
  "video/mkv",
  "video/flv",
];

export const acceptedImageType = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "images/jfif",
  "images/heic",
  ".heic",
];

export const commonText = {
  next: "次へ",
  return: "戻る",
  save: "保存する",
  filter: "絞り込む",
  no: "いいえ",
  yes: "はい",
  newSort: "新着",
  crop: "切り取る",
};
