import { CaretDownOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown, Select } from "antd";
import { images } from "assets";
import {
  IconLock,
  IconLogout,
  IconNotification,
  IconProfileSm,
} from "assets/icons";
import CommonSelect from "components/CommonSelect/CommonSelect";
import configs from "constants/config";
import { LanguageType, LocalStorageCode, QueryKey } from "constants/enums";
import i18next from "i18next";
import NotificationHeader from "onesignal/NotificationHeader";
import ListNotificationReceived from "pages/Dashboard/components/ListNotificationReceived";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "utils/cn";
import { logoutWithNotApi } from "utils/helper/authentication";
import useProfile from "utils/hooks/useProfile";
import routePaths from "utils/routePaths";
import styles from "./styles.module.scss";

export default function PageHeader() {
  const location = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const profile = useProfile((state) => state.profile);
  const queryClient = useQueryClient();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    configs.isDebugOrDev
      ? localStorage.getItem(LocalStorageCode.I18) || LanguageType.JA
      : LanguageType.JA
  );
  const [openNotification, setOpenNotification] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <div
          className={cn(
            "flex items-center gap-1 hover:text-primary",
            location.pathname === routePaths.mypage && "text-primary"
          )}
          onClick={() => {
            navigate(routePaths.mypage);
          }}
        >
          <IconProfileSm />
          <span>{t("common.profile")}</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className={cn(
            "flex items-center gap-1 hover:text-primary",
            location.pathname === routePaths.changePassword && "text-primary"
          )}
          onClick={() => {
            navigate(routePaths.changePassword);
          }}
        >
          <IconLock />
          <span>{t("common.changePassword")}</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="flex items-center gap-1 hover:text-primary"
          onClick={() => {
            logoutWithNotApi();
          }}
        >
          <IconLogout />
          <span>{t("common.logout")}</span>
        </div>
      ),
    },
  ];

  const handleSelectLanguageChange = (value: any) => {
    setCurrentLanguageCode(value);
    localStorage.setItem(LocalStorageCode.I18, value);
    i18next.changeLanguage(value);
  };

  return (
    <div className={styles.headerWrapper}>
      <div className="w-side-nav h-full flex items-center justify-center">
        <Link to="/" className={"flex justify-center items-center w-[90px]"}>
          <img src={images.logo} alt="logo" className="object-contain" />
        </Link>
      </div>
      <div className={styles.actions}>
        {configs.isDebugOrDev && (
          <CommonSelect
            className={styles.languages}
            onChange={handleSelectLanguageChange}
            value={currentLanguageCode}
          >
            ``
            <Select.Option value={LanguageType.JA}>
              {t("languages.japanese")}
            </Select.Option>
            <Select.Option value={LanguageType.EN}>
              {t("languages.english")}
            </Select.Option>
            <Select.Option value={LanguageType.VI}>
              {t("languages.vietnamese")}
            </Select.Option>
            <Select.Option value={LanguageType.KEY}>
              {t("languages.showKey")}
            </Select.Option>
          </CommonSelect>
        )}
        <div className={styles.menuWrapper}>
          <Dropdown
            open={openNotification}
            trigger={["click"]}
            dropdownRender={(originNode) => (
              <ListNotificationReceived
                setOpenNotification={setOpenNotification}
              />
            )}
            onOpenChange={(open) => {
              setOpenNotification(open);
              if (!open) {
                queryClient.removeQueries([
                  QueryKey.LIST_NOTIFICATION_RECEIVED,
                ]);
              }
            }}
          >
            <div
              className={"flex items-center ml-3 gap-1 p-2 cursor-pointer"}
              onClick={() => setOpenNotification(!openNotification)}
            >
              <NotificationHeader>
                <IconNotification className={"text-primary"} />
              </NotificationHeader>
              <span className={""}>{t("header.notification")}</span>
            </div>
          </Dropdown>

          <div className={styles.menuItem}>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <div>
                <span>{profile?.username || profile?.email}</span>
                &nbsp;
                <CaretDownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
