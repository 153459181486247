const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  WEB_USER_DOMAIN: process.env.REACT_APP_WEB_USER_DOMAIN,
  ONE_SIGNAL_APP_ID: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
  isDebug: process.env.NODE_ENV === "development",
  isDev: process.env.REACT_APP_ENV === "dev",
  isDebugOrDev:
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENV === "dev",
  isProduction: process.env.REACT_APP_ENV === "prod",
};

export default configs;
