"use client";

import { useQueryClient } from "@tanstack/react-query";
import configs from "constants/config";
import { SocketEvent } from "constants/enums";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Socket, io } from "socket.io-client";
import { handleErrorMessage } from "utils/helper";
import storage from "utils/helper/storage";

const SocketContext = createContext<Socket | null>(null);

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const refreshToken = storage.getRefreshToken();
  const socketRef = useRef<Socket | null>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    // console.log(configs.SOCKET_URL)
    const newSocket = io(configs.SOCKET_URL as string, {
      extraHeaders: { authorization: refreshToken || "" },
      withCredentials: configs.isProduction,
    });
    newSocket?.on(SocketEvent.CONNECT, () => {
      console.log("Connect socket successful");
    });
    newSocket.on(SocketEvent.SERVER_ERROR, (data: any) => {
      console.log("---SERVER_ERROR---", data);
    });
    setSocket(newSocket);
    socketRef.current = newSocket;

    const disconnect = () => {
      if (socket) {
        // console.log("-----DISCONNECT SOCKET-----")
        newSocket?.off(SocketEvent.CONNECT);
        newSocket?.off(SocketEvent.SERVER_ERROR);
        socket.disconnect();
      }
    };

    window.addEventListener("beforeunload", disconnect);

    return () => {
      window.removeEventListener("beforeunload", disconnect);
      disconnect();
    };
  }, [refreshToken]);

  useEffect(() => {
    if (!socket) return;
    socket?.on(SocketEvent.SERVER_ERROR, handleErrorMessage);

    return () => {
      socket?.off(SocketEvent.SERVER_ERROR, handleErrorMessage);
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socket = useContext(SocketContext);

  if (!socket) {
    console.error("Has error when connect socket");
  }

  return socket;
};
