import { LanguageType } from "constants/enums";
import Axios from "axios";

import configs from "constants/config";
import storage from "utils/helper/storage";
import { logoutWithNotApi } from "utils/helper/authentication";
import { handleErrorMessage } from "utils/helper";
import i18n from "i18n/i18n";

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

const logoutWithTimeout = () => {
  setTimeout(() => {
    logoutWithNotApi();
  }, 2000);
};

axiosInstance.interceptors.request.use(
  (config: any) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      "Accept-Language": LanguageType.JA,
      ...config.headers,
    };
    const token = storage.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalConfig = error.config;
    if (error.response.status !== 401) {
      handleErrorMessage(error);
      if (
        error.response?.data?.errorCode === "Account_Inactive" &&
        window.location.pathname !== "/login"
      ) {
        logoutWithTimeout();
        return;
      }
      return Promise.reject(error);
    }

    const refreshToken = storage.getRefreshToken();
    if (!refreshToken) {
      handleErrorMessage(i18n.t("common.sessionExpired"));
      logoutWithTimeout();
      return;
    }

    return Axios.post(`${configs.API_DOMAIN}/cms/auth/refresh-token`, {
      refreshToken,
    })
      .then((res) => {
        if (res.status === 201) {
          const data = res.data;
          console.log(data);
          storage.setToken(data.data.token);
          originalConfig.headers.Authorization = `Bearer ${data.data.token}`;
          return Axios(originalConfig);
        } else {
          handleErrorMessage(i18n.t("common.sessionExpired"));
          logoutWithTimeout();
          return;
        }
      })
      .catch((err: any) => {
        handleErrorMessage(err);
        logoutWithTimeout();
        return;
      });
  }
);

export const sendGet = <T = any>(url: string, params?: any, headers?: any) =>
  axiosInstance.get(url, { params, headers }).then<T>((res) => res.data);

export const sendPost = <T = any>(
  url: string,
  params?: any,
  queryParams?: any,
  headers?: any
) =>
  axiosInstance
    .post(url, params, { params: queryParams, headers })
    .then<T>((res) => res.data);

export const sendPut = <T = any>(url: string, params?: any, headers?: any) =>
  axiosInstance.put(url, params, { headers }).then<T>((res) => res.data);

export const sendPatch = <T = any>(url: string, params?: any, headers?: any) =>
  axiosInstance.patch(url, params, { headers }).then<T>((res) => res.data);

export const sendDelete = <T = any>(url: string, params?: any, headers?: any) =>
  axiosInstance.delete(url, { params, headers }).then<T>((res) => res.data);
