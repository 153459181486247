import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getProfile = () => sendGet("/cms/profile");

export const postLogin = (payload: any) => sendPost("/cms/auth/login", payload);

export const changePasswordApi = (payload: any) =>
  sendPut("cms/admin-auth/change-password", payload);

export const updateProfile = (params: any) => sendPut("/cms/profile", params);

export const requestOTPForgotPasswordApi = (params: { email: string }) =>
  sendPost("/cms/auth/forgot-password/request", params);

export const confirmOTPForgotPasswordApi = (params: {
  email: string;
  otp: string;
}) => sendPost("/cms/auth/forgot-password/confirm-otp", params);

export const createNewPasswordForgotPasswordApi = (params: {
  email: string;
  otp: string;
  password: string;
}) => sendPost("/cms/auth/forgot-password/create-new-password", params);
