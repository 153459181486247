import configs from "constants/config";
import OneSignal from "react-onesignal";
const ADMIN_TAG = "admin_id";

export default async function runOneSignal() {
  await OneSignal.init({
    appId: configs.ONE_SIGNAL_APP_ID as string,
    allowLocalhostAsSecureOrigin: true,
  });
}

export const addTagOnesignal = async (userId: number) => {
  try {
    if (userId) {
      OneSignal.login(userId + "").then(async () => {
        OneSignal.User.addTag(ADMIN_TAG, userId + "");
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeTagOnesignal = () => {
  console.log("start removeTagOnesignal");
  try {
    OneSignal.User.removeTag(ADMIN_TAG);
  } catch (error) {
    console.log("---error removeTagOnesignal---", error);
  }
  OneSignal.logout();
  console.log("end removeTagOnesignal");
};
