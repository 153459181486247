import React from "react";
import { formatNumber } from "utils/format";

interface IProps {
  title: string;
  total: number | undefined;
}

const TotalItemsPage = ({ title, total = 0 }: IProps) => {
  return (
    <div className="flex items-center gap-1">
      <p className="text_16">{title}:</p>
      <p className="text_20-bold">{formatNumber(total)}</p>
    </div>
  );
};

export default TotalItemsPage;
