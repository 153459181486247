import { useQuery } from "@tanstack/react-query";
import {
  IFilterListLiveStreaming,
  listLiveStreamingApi,
} from "api/live-stream";
import HeaderContent from "components/HeaderContent";
import TotalItemsPage from "components/TotalItemsPage/TotalItemsPage";
import { DEFAULT_FILTER } from "constants/defaultValues";
import { QueryKey, UserType } from "constants/enums";
import { useTranslation } from "react-i18next";
import useFilter from "utils/hooks/useFilter";
import LiveStreamingFilter from "./components/LiveStreamingFilter";
import LiveStreamingTable from "./components/LiveStreamingTable";

export default function LiveStreamingList() {
  const { t } = useTranslation();

  const { filter, handleSearch, handlePageChange } =
    useFilter<IFilterListLiveStreaming>({
      ...DEFAULT_FILTER,
      userType: [UserType.ORGANIZATION, UserType.STREAMER],
    });

  const { data: dataListLiveStreaming, isLoading: isLoadingListLiveStreaming } =
    useQuery([QueryKey.LIST_LIVE_STREAMING, filter], () =>
      listLiveStreamingApi(filter)
    );

  return (
    <div>
      <HeaderContent title={t("nav.liveStreamingManagement")}></HeaderContent>
      <div className="table-container">
        <div className="flex items-center justify-between flex-wrap">
          <TotalItemsPage
            title={t("total.liveStreaming")}
            total={dataListLiveStreaming?.totalItems}
          />
          <LiveStreamingFilter filter={filter} handleSearch={handleSearch} />
        </div>

        <LiveStreamingTable
          data={dataListLiveStreaming?.data}
          isLoading={isLoadingListLiveStreaming}
          onPageChange={handlePageChange}
          pageIndex={filter?.pageIndex || 1}
          pageSize={filter?.pageSize || 1}
          totalItems={dataListLiveStreaming?.totalItems || 1}
        />
      </div>
    </div>
  );
}
