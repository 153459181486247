import { Suspense, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { getProfileApi } from "api/staff";
import PageHeader from "components/PageHeader";
import SideNav from "components/SideNav";
import { QueryKey } from "constants/enums";
import { addTagOnesignal, removeTagOnesignal } from "onesignal/onesignal";
import storage from "utils/helper/storage";
import useProfile from "utils/hooks/useProfile";
import routePaths from "utils/routePaths";
import styles from "./styles.module.scss";
import useReadNotification from "utils/hooks/useReadNotification";

export default function AuthWrapper() {
  const isAuthenticated = !!storage.getRefreshToken();
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useProfile((state) => state.profile);
  const setProfile = useProfile((state) => state.setProfile);
  useReadNotification();

  useQuery([QueryKey.GET_PROFILE], () => getProfileApi(), {
    enabled: !!isAuthenticated,
    onSuccess: (data) => {
      setProfile(data?.data);
      addTagOnesignal(data?.data?.id);
    },
  });

  useEffect(() => {
    if (
      profile.isLoginFirstTime &&
      location.pathname !== routePaths.changePassword
    ) {
      navigate(routePaths.changePassword, { replace: true });
    }
  }, [location.pathname, profile]);

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <div className={styles.pageWrapper}>
      <PageHeader />
      <div className={styles.mainWrapper}>
        <SideNav />
        <div className={styles.pageContent}>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
