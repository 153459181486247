import LiveStreamingList from "pages/LiveStreamings";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import useResources from "utils/hooks/useResources";
import routePaths from "utils/routePaths";
import AuthWrapper from "wrappers/AuthWrapper";

// DASHBOARD
const Dashboard = lazy(() => import("pages/Dashboard"));

// USER
const UserList = lazy(() => import("pages/Users"));
const UserDetail = lazy(() => import("pages/Users/detail/UserDetail"));

// STREAMER
const StreamerList = lazy(() => import("pages/Streamers/list/StreamerList"));
const RequestBecomeStreamerList = lazy(
  () => import("pages/Streamers/request/RequestBecomeStreamerList")
);

const DetailStreamerRequest = lazy(
  () => import("pages/Streamers/components/DetailStreamerRequest")
);
const RequestDowngradeAccountList = lazy(
  () => import("pages/Streamers/request/RequestDowngradeAccountList")
);
const StreamerDetail = lazy(
  () => import("pages/Streamers/detail/StreamerDetail")
);

const RecruitmentList = lazy(() => import("pages/Recruitments"));

// SNS
const SnsList = lazy(() => import("pages/Sns"));

// NOTIFICATIONS
const Notifications = lazy(() => import("pages/Notifications"));

// SUBSCRIBE
const SubscribeAnimaru = lazy(() => import("pages/Subscribe/Animaru"));
const SubscribeUser = lazy(() => import("pages/Subscribe/Streamer"));

// CONTACT
const ContactList = lazy(() => import("pages/Contact"));
const ContactDetail = lazy(
  () => import("pages/Contact/components/ContactDetail")
);

// CHAT
const ChatList = lazy(() => import("pages/Chat"));

// MASTER DATA
const RateExchange = lazy(() => import("pages/MasterData/RateExchange"));
const GiftList = lazy(() => import("pages/MasterData/Gift"));
const CategoryList = lazy(() => import("pages/MasterData/Category"));
const BannerList = lazy(() => import("pages/MasterData/Banner"));

// STATIC PAGE
const Terms = lazy(() => import("pages/StaticPage/Terms"));
const Policy = lazy(() => import("pages/StaticPage/Policy"));

const Mypage = lazy(() => import("pages/Mypage"));
const Login = lazy(() => import("pages/Login"));
const NotFound = lazy(() => import("pages/NotFound"));
const AccessDenied = lazy(() => import("pages/AccessDenied"));
const RevenueRequestList = lazy(
  () => import("pages/Revenue/RevenueRequestList")
);

// FORGOT PASSWORD
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

// REPORT
const ReportLiveStream = lazy(() => import("pages/Report/LiveStream"));
const DetailReportLiveStream = lazy(
  () => import("pages/Report/LiveStream/DetailReportLiveStream")
);

const ReportRecruitment = lazy(() => import("pages/Report/Recruitment"));
const DetailReportRecruitment = lazy(
  () => import("pages/Report/Recruitment/DetailReportRecruitment")
);
const ReportSNS = lazy(() => import("pages/Report/SNS"));
const DetailReportSns = lazy(() => import("pages/Report/SNS/DetailReportSns"));
const Review = lazy(() => import("pages/Report/Review"));

// COIN HISTORY
const CoinHistory = lazy(() => import("pages/CoinHistory"));

// STAFF
const Staffs = lazy(() => import("pages/Staff"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));

export default function RootWrapper() {
  useResources();

  return (
    <Routes>
      <Route path="/" element={<AuthWrapper />}>
        <Route path="/mypage" element={<Mypage />} />

        {/* USERS */}
        <Route path={routePaths.dashboard} element={<Dashboard />} />

        {/* USERS */}
        <Route path={routePaths.user.list} element={<UserList />} />
        <Route
          path={`${routePaths.user.detail}/:userId`}
          element={<UserDetail />}
        />

        {/* LIVE STREAM */}
        <Route
          path={routePaths.liveStreaming}
          element={<LiveStreamingList />}
        />

        {/* STREAMERS */}
        <Route path={routePaths.streamer.list} element={<StreamerList />} />
        <Route
          path={routePaths.streamer.request}
          element={<RequestBecomeStreamerList />}
        />
        <Route
          path={routePaths.streamer.requestDowngrade}
          element={<RequestDowngradeAccountList />}
        />
        <Route
          path={`${routePaths.streamer.detail}/:userId`}
          element={<StreamerDetail />}
        />
        <Route
          path={`${routePaths.streamer.request}/:requestId`}
          element={<DetailStreamerRequest />}
        />
        <Route
          path={`${routePaths.streamer.requestDowngrade}/:requestId`}
          element={<DetailStreamerRequest />}
        />

        {/* SNS */}
        <Route path={routePaths.sns} element={<SnsList />} />

        {/* REVENUE */}
        <Route path={routePaths.revenue} element={<RevenueRequestList />} />

        {/* RECRUITMENTS */}
        <Route path={routePaths.recruitment} element={<RecruitmentList />} />

        {/* NOTIFICATIONS */}
        <Route path={routePaths.notification} element={<Notifications />} />

        {/* SUBSCRIBE */}
        <Route
          path={routePaths.subscription.animaru}
          element={<SubscribeAnimaru />}
        />
        <Route
          path={routePaths.subscription.streamer}
          element={<SubscribeUser />}
        />
        {/* CONTACT */}
        <Route path={routePaths.contact} element={<ContactList />} />
        <Route
          path={`${routePaths.contact}/:contactId`}
          element={<ContactDetail />}
        />

        {/* CHAT */}
        <Route path={routePaths.chat.list} element={<ChatList />} />

        {/* MASTER DATA */}
        <Route
          path={routePaths.masterData.rateExchange}
          element={<RateExchange />}
        />
        <Route path={routePaths.masterData.gift} element={<GiftList />} />
        <Route
          path={routePaths.masterData.category}
          element={<CategoryList />}
        />
        <Route path={routePaths.masterData.banner} element={<BannerList />} />

        {/* STATIC PAGE */}
        <Route path={routePaths.staticPage.terms} element={<Terms />} />
        <Route path={routePaths.staticPage.policy} element={<Policy />} />

        {/* REPORT */}
        <Route
          path={routePaths.report.liveStream}
          element={<ReportLiveStream />}
        />
        <Route
          path={`${routePaths.report.liveStream}/:id`}
          element={<DetailReportLiveStream />}
        />
        <Route path={routePaths.report.sns} element={<ReportSNS />} />
        <Route
          path={`${routePaths.report.sns}/:id`}
          element={<DetailReportSns />}
        />
        <Route
          path={routePaths.report.recruitment}
          element={<ReportRecruitment />}
        />
        <Route
          path={`${routePaths.report.recruitment}/:id`}
          element={<DetailReportRecruitment />}
        />
        <Route path={routePaths.report.review} element={<Review />} />

        {/* COIN HISTORY */}
        <Route path={routePaths.coinHistory} element={<CoinHistory />} />
        {/* STAFF */}
        <Route path={routePaths.staff} element={<Staffs />} />
        <Route path={routePaths.changePassword} element={<ChangePassword />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="access-denied" element={<AccessDenied />} />
      <Route path="*" element={<NotFound />} />
      {/* FORGOT PASSWORD */}
      <Route path={routePaths.forgotPassword} element={<ForgotPassword />} />
    </Routes>
  );
}
