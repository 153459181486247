import { TimeUnit } from "constants/enums";
import dayjs, { OpUnitType } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Dayjs } from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

export enum FormatTime {
  YYYYMMDD_HHmm = "YYYY/MM/DD HH:mm",
  DATE = "DD/MM/YYYY",
  DATE_REVERSE = "YYYY/MM/DD",
  DATE_JP = "YYYY年MM月DD日",
  DATE_TIME_JP = "YYYY年MM月DD日 HH:mm",
  YYYYMMDD = "YYYY/MM/DD",
  YYYYMM = "YYYY/MM",
  HHmm = "HH:mm",
  JAPANESE = "YYYY年MM月DD日",
}

export const convertDateToStartEnd = (
  dateTime: any,
  unit: OpUnitType = "D"
) => {
  return {
    startTime: dayjs(dateTime).startOf(unit).toISOString(),
    endTime: dayjs(dateTime).endOf(unit).toISOString(),
  };
};

export const formatDate = (time?: string | Date, format?: FormatTime) => {
  if (!time) return "";
  return dayjs(time)
    .format(format || FormatTime.YYYYMMDD)
    .toString();
};

export const getRemainDaysInMonth = () => {
  const currentDate = dayjs();
  const daysInMonth = currentDate.daysInMonth();
  const currentDay = currentDate.date();

  return Math.abs(daysInMonth - currentDay);
};

export const getDayMonthYear = () => {
  const currentDate = dayjs();
  return {
    day: currentDate.date(),
    month: currentDate.month() + 1,
    year: currentDate.year(),
  };
};

export const changeLocale = (locale: string) => {
  return dayjs().locale(locale);
};

export const convertTimeToUTC = (dateTime: any | null) => {
  if (!dateTime) return undefined;
  return dateTime.toISOString();
};

export const getTimeStartDay = (
  date: string | Date = new Date(),
  withTimezone = true
) => {
  if (withTimezone) {
    return dayjs(date).tz().startOf("D").toISOString();
  }
  return dayjs(date).startOf("D").toISOString();
};

export const getTimeEndDay = (
  date: string | Date = new Date(),
  withTimezone = true
) => {
  if (withTimezone) {
    return dayjs(date).tz().endOf("D").toISOString();
  }
  return dayjs(date).endOf("D").toISOString();
};

export const getDayStartOfMonthOrYear = ({
  date = new Date(),
  withTimezone = true,
  unit = TimeUnit.Month,
}: {
  date?: string | Date | Dayjs;
  withTimezone?: boolean;
  unit?: OpUnitType;
}) => {
  if (withTimezone) {
    return dayjs(date).tz().startOf(unit).millisecond(0).toISOString();
  }
  return dayjs(date).startOf(unit).millisecond(0).toISOString();
};

export const getDayEndOfMonthOrYear = ({
  date = new Date(),
  withTimezone = true,
  unit = TimeUnit.Month,
}: {
  date?: string | Date | Dayjs;
  withTimezone?: boolean;
  unit?: OpUnitType;
}) => {
  if (withTimezone) {
    return dayjs(date).tz().endOf(unit).millisecond(0).toISOString();
  }
  return dayjs(date).endOf(unit).millisecond(0).toISOString();
};
