import React, { useEffect } from "react";
import runOneSignal, { removeTagOnesignal } from "./onesignal";
import storage from "utils/helper/storage";

const useOneSignal = () => {
  const isAuthenticated = !!storage.getRefreshToken();

  useEffect(() => {
    runOneSignal();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // runOneSignal();
    } else {
      removeTagOnesignal();
    }
  }, [isAuthenticated]);
};

export default useOneSignal;
