import managePost from "assets/images/archive-book.png";
import arrowLeftIcon from "assets/images/arrow-left-icon.png";
import manageReport from "assets/images/clipboard-close.png";
import defaultAvatar from "assets/images/default-avatar.png";
import defaultImage from "assets/images/default-image.png";
import manageDoctor from "assets/images/doctor 1.png";
import managePostCommunity from "assets/images/document-text.png";
import manageClinic from "assets/images/hospital.png";
import logo from "assets/images/logo.png";
import logoSquare from "assets/images/logo-square.png";
import manageReview from "assets/images/message-favorite.png";
import manageOffer from "assets/images/note-favorite.png";
import manageNotification from "assets/images/notification-bing.png";
import manageStaff from "assets/images/profile-2user.png";
import manageUser from "assets/images/profile.png";
import manageMasterData from "assets/images/setting-2.png";
import manageRanking from "assets/images/star.png";
import manageSwipe from "assets/images/stickynote.png";
import badgeProfile from "assets/images/badge-profile.png";

export const images = {
  arrowLeftIcon,
  defaultAvatar,
  defaultImage,
  logo,
  manageStaff,
  manageUser,
  manageDoctor,
  manageClinic,
  managePost,
  manageSwipe,
  manageMasterData,
  manageRanking,
  manageReview,
  manageNotification,
  managePostCommunity,
  manageReport,
  manageOffer,
  logoSquare,
  badgeProfile,
};
