import React, { ReactNode, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "utils/hooks/useSocket";
import storage from "utils/helper/storage";
import { QueryKey, SocketEvent } from "constants/enums";
import { getTotalUnreadNotificationApi } from "api/notifications";
import { cn } from "utils/cn";

interface IProps {
  children?: ReactNode;
}

const NotificationHeader = ({ children }: IProps) => {
  const socket = useSocket();
  const queryClient = useQueryClient();
  const refreshToken = storage.getRefreshToken();
  const [hasUnreadNotification, setHasUnreadNotification] = useState(false);

  const { data, isSuccess } = useQuery({
    queryKey: [QueryKey.TOTAL_UNREAD_NOTIFICATION],
    queryFn: getTotalUnreadNotificationApi,
    enabled: !!refreshToken,
  });

  const totalUnread = data?.data?.totalUnread;

  // useEffect(() => {
  //   if (isSuccess && data?.data?.totalUnread > 0) {
  //     setHasUnreadNotification(true);
  //   } else {
  //     setHasUnreadNotification(false);
  //   }
  // }, [data?.data]);

  const handleUpdateTotalUnread = (data: any) => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.TOTAL_UNREAD_NOTIFICATION],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKey.LIST_NOTIFICATION_RECEIVED],
    });
  };

  useEffect(() => {
    if (socket) {
      socket?.on(
        SocketEvent.UPDATE_TOTAL_UNREAD_NOTIFICATION,
        handleUpdateTotalUnread
      );
    }
    return () => {
      socket?.off(
        SocketEvent.UPDATE_TOTAL_UNREAD_NOTIFICATION,
        handleUpdateTotalUnread
      );
    };
  }, [socket]);

  return (
    <div className="relative">
      {children}
      {!!totalUnread && (
        <div className="absolute right-[-3px] top-[-5px] h-[18px] w-[18px] rounded-full border border-white bg-[#FF503D] flex items-center justify-center overflow-hidden">
          <p
            className={cn("text-[12px] !leading-none text-white", {
              "text-[8px]": totalUnread > 99,
            })}
          >
            {totalUnread > 99 ? "99+" : totalUnread}
          </p>
        </div>
      )}
    </div>
  );
};

export default NotificationHeader;
