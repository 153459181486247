import { CommonStatus, UserType } from "constants/enums";
import {
  IResponsePaging,
  IDefaultSearchParams,
  IFilter,
  IResponse,
} from "constants/interfaces";
import { ILiveEndedItem, ILiveStreamingItem } from "interfaces/live-stream";
import { sendGet, sendPut } from "./axios";

export interface IPayloadStarLiveStreaming {
  status: CommonStatus;
}

export interface IFilterListLiveStreamEnded extends IFilter {
  streamerId?: number;
  isLiked?: CommonStatus;
}

export interface IPayloadHideLiveStreamEnded {
  liveStreamId: number;
  isHide: CommonStatus;
}

export interface IFilterListLiveStreaming extends IDefaultSearchParams {
  userType?: (UserType.ORGANIZATION | UserType.STREAMER)[];
}

export const listLiveStreamingApi = (
  params: IFilterListLiveStreaming
): Promise<IResponsePaging<ILiveStreamingItem[]>> =>
  sendGet("/cms/live-stream/list-live-streaming", params);

export const stopLiveStreamApi = (
  liveStreamId: number | string
): Promise<any> => sendPut(`/cms/live-stream/stop-live-stream/${liveStreamId}`);

export const starLiveStreamApi = ({
  payload,
  liveStreamId,
}: {
  payload: IPayloadStarLiveStreaming;
  liveStreamId: number | string;
}): Promise<any> => sendPut(`/cms/live-stream/star/${liveStreamId}`, payload);

export const getListLiveStreamEndedApi = (
  params?: IFilterListLiveStreamEnded
): Promise<IResponsePaging<ILiveEndedItem[]>> =>
  sendGet(`/cms/live-stream/ended`, params);

export const hideLiveStreamEndedApi = (
  payload: IPayloadHideLiveStreamEnded
): Promise<IResponse<boolean>> => sendPut(`/cms/live-stream/hide`, payload);

export const deleteLiveStreamEndedApi = (payload: {
  liveStreamId: number;
}): Promise<IResponse<boolean>> => sendPut(`/cms/live-stream/delete`, payload);
