"use client";

import React from "react";
import { useMutation } from "@tanstack/react-query";
import { readNotificationApi } from "api/notifications";
import { INotificationReceivedItem } from "interfaces/notification";
import { cn } from "utils/cn";
import { Image, ImageProps } from "antd";
import { images } from "assets";
import { IconFoot } from "assets/icons";
import { FormatTime, formatDate } from "utils/date";
import { useNavigate } from "react-router-dom";
import { ReadNotification } from "constants/enums";
import queryString from "query-string";

interface IProps {
  item: INotificationReceivedItem;
  setOpenNotification?: (open: boolean) => void;
}

const NotificationItem = ({ item, setOpenNotification }: IProps) => {
  const navigate = useNavigate();
  const { mutate: readNotification } = useMutation({
    mutationFn: readNotificationApi,
  });

  const handleClickNotification = async () => {
    // dont need because used useReadNotification
    // if (item.isRead === ReadNotification.UNREAD) {
    //   await readNotification(item?.id);
    // }
    if (item?.redirectUrl) {
      navigate(
        queryString.stringifyUrl({
          url: item?.redirectUrl,
          query: {
            isRead: item.isRead,
          },
        })
      );
      setOpenNotification?.(false);
    }
  };

  return (
    <div
      className={cn(
        "flex cursor-pointer items-start gap-4 border-b border-grey-200 p-4 w-full hover:bg-grey-100"
      )}
      onClick={handleClickNotification}
    >
      <div
        className={cn(
          "relative flex h-8 w-8 min-w-8 items-center justify-center rounded-full",
          !item?.userCreate?.id && "bg-primary"
        )}
      >
        {item?.userCreate?.id ? (
          <img
            src={item?.userCreate?.avatar || images.defaultAvatar}
            alt="avatar"
            width={32}
            height={32}
            className="rounded-full object-cover bg-primary"
          />
        ) : (
          <IconFoot className="text-primary-100" />
        )}
        {!item?.isRead && (
          <div className="absolute right-[-4px] top-[-1px] h-[12px] w-[12px] rounded-full border border-white bg-[#FF503D]" />
        )}
      </div>
      <div>
        <p className="text_12 text-grey-400">
          {formatDate(item?.createdAt as string, FormatTime.YYYYMMDD_HHmm)}
        </p>
        <h5 className="text_14-bold break-words-util line-clamp-1">
          {item?.title}
        </h5>
        <p className="text_14 break-words-util line-clamp-2 text-grey-400">
          {item?.content}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
