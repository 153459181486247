import { Input, InputProps } from "antd";
import Icon from "@ant-design/icons";
import { IconSearch } from "assets/icons";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import classNames from "classnames";
import { cn } from "utils/cn";

interface IProps extends InputProps {
  onSearchKeyword: (value: string) => void;
  placeholder: string;
  debounceTime?: number;
  sizeWidth?: number;
}

function InputSearch({
  onSearchKeyword,
  placeholder,
  debounceTime = 1000,
  className,
  sizeWidth = 200,
  ...otherProps
}: IProps) {
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      onSearchKeyword(value);
    },
    // delay in ms
    debounceTime
  );

  return (
    <Input
      size="small"
      className={cn("input w-[200px] h-[48px]", className)}
      onChange={(e) => debounced(e.target.value)}
      placeholder={placeholder}
      prefix={
        <Icon component={() => <IconSearch className="text-grey-400" />} />
      }
      {...otherProps}
    />
  );
}

export default InputSearch;
