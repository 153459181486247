import { IModalMessageProps } from "components/Modal/ModalMessage";
import { create } from "zustand";

interface IControllerModal {
  modalProps?: IModalMessageProps;
  setModalProps: (modalProps: IModalMessageProps) => void;
  openModal: (modalProps: IModalMessageProps) => void;
  closeModal: () => void;
}

const useModal = create<IControllerModal>()((set) => ({
  setModalProps(modalProps: IModalMessageProps) {
    set({ modalProps });
  },
  openModal(modalProps: IModalMessageProps) {
    set({ modalProps: { ...modalProps, open: true } });
  },
  closeModal() {
    set((prevState: IControllerModal) => ({
      modalProps: { ...prevState.modalProps, open: false },
    }));
  },
}));

export default useModal;
