import { DEFAULT_FILTER } from "constants/defaultValues";
import { CommonValue, DebounceTime, LanguageType } from "constants/enums";
import { IFilter } from "constants/interfaces";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export default function useFilter<T = IFilter>(defaultFilter?: T) {
  const [headersRequest, setHeadersRequest] = useState({
    "Accept-Language": LanguageType.JA,
  });
  const [filter, setFilter] = useState<T>(
    (defaultFilter ?? DEFAULT_FILTER) as T
  );

  const handleFilterChange = (
    changeValue: T = {} as T,
    resetPageIndex = true
  ) => {
    setFilter({
      ...filter,
      ...changeValue,
      ...(resetPageIndex ? { pageIndex: 1 } : {}),
    });
  };

  const paramsSearch = (params: {
    [key: string]: string | number | undefined | any[];
  }) => {
    handleFilterChange({ ...params } as T);
  };

  const keywordSearch = useDebouncedCallback((keyword) => {
    handleFilterChange({
      keyword,
    } as T);
  }, DebounceTime.DEFAULT);

  const rangeDateSearch = (dateRange: any | null) => {
    return handleFilterChange({
      startDate: dateRange?.[0]?.startOf("day")?.toISOString(),
      endDate: dateRange?.[1]?.endOf("day")?.toISOString(),
    } as T);
  };

  const statusSearch = (value: any) => {
    if (value === CommonValue.ALL) {
      handleFilterChange({ status: undefined } as T);
    } else {
      handleFilterChange({ status: value } as T);
    }
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setFilter({ ...filter, pageIndex: page, pageSize });
  };

  const resetFilter = () => {
    if (defaultFilter) {
      setFilter({ ...defaultFilter });
    } else {
      setFilter(DEFAULT_FILTER as T);
    }
  };

  const changeAcceptLanguage = (lng: LanguageType = LanguageType.JA) => {
    setHeadersRequest((prevState: any) => ({
      ...prevState,
      "Accept-Language": lng,
    }));
  };

  return {
    filter,
    handleFilterChange,
    handlePageChange,
    resetFilter,
    headersRequest,
    currentLanguage: headersRequest["Accept-Language"],
    setHeadersRequest,
    changeAcceptLanguage,
    handleSearch: {
      keywordSearch,
      statusSearch,
      rangeDateSearch,
      paramsSearch,
    },
  };
}
